import { text_mod_31, text_mod_32, text_mod_33 } from "../text/Text";

export const module11 = {
  title: "Український народний героїчний ліро-епос. Народні думи. «Дума про Марусю Богуславку»",
  questions: [
    {
      id: 1,
      subtitle: "ДІЗНАЙСЯ БІЛЬШЕ",
      onlyText: true,
      text: text_mod_31, },

      {
        id: 2,
        subtitle: "ДОСЛІДЖУЄМО МЕДІА",
        onlyText: true,
        text: text_mod_32, },

        {
          id: 3,
          subtitle: "ЧИТАЦЬКЕ ДОЗВІЛЛЯ",
          onlyText: true,
          text: text_mod_33, },
    {
      id: 4,
      question: "1. Яке з тверджень є НЕПРАВИЛЬНИМ?",
      options: [
        { id: "А", text: "Думи мають усталену композицію." },
        { id: "Б", text: "Термін «дума» увів фольклорист Михайло Максимович." },
        { id: "В", text: "Думи складаються з рівноскладових рядків." },
        { id: "Г", text: "За змістом думи поділяються на історичні та соціально-побутові." },
      ],
      subtitle: "Тестові питання",
      correctAnswer: "В",
      isTest: true,
    },
    {
      id: 5,
      question: "2. Характерною формою виконання дум є",
      options: [
        { id: "А", text: "речитатив" },
        { id: "Б", text: "спів" },
        { id: "В", text: "розповідь" },
        { id: "Г", text: "інсценізація" },
      ],
      correctAnswer: "А",
      isTest: true,
    },
    {
      id: 6,
      question: "3. Рядки 'Вислухай, Боже, у просьбах щирих, / У нещасних молитвах / Нас, бідних невольників!' у композиції «Думи про Марусю Богуславку» є",
      options: [
        { id: "А", text: "заплачкою" },
        { id: "Б", text: "розповіддю" },
        { id: "В", text: "славослів’ям" },
        { id: "Г", text: "ліричним відступом" },
      ],
      correctAnswer: "В",
      isTest: true,
    },
    {
      id: 7,
      question: "4. Події «Думи про Марусю Богуславку» відбуваються",
      options: [
        { id: "А", text: "на Різдво" },
        { id: "Б", text: "на Великдень" },
        { id: "В", text: "на Трійцю" },
        { id: "Г", text: "на Покрову" },
      ],
      correctAnswer: "Б",
      isTest: true,
    },
    {
      id: 8,
      question: "5. У «Думі про Марусю Богуславку» використано художні засоби:",
      options: [
        { id: "А", text: "постійні епітети й риторичні звертання" },
        { id: "Б", text: "постійні епітети й порівняння" },
        { id: "В", text: "риторичні звертання та гіпербола" },
        { id: "Г", text: "порівняння та метафора" },
      ],
      correctAnswer: "А",
      isTest: true,
    },
    {
      id: 9,
      question: "6. Що попросила Маруся у козаків?",
      options: [
        { id: "А", text: "Хотіла, аби викупили її з неволі." },
        { id: "Б", text: "Передати вісточку батькам, що вона жива." },
        { id: "В", text: "Просила не розповідати про неї." },
        { id: "Г", text: "Помолитися за неї." },
      ],
      correctAnswer: "Б",
      isTest: true,
    },
    // Додати нові питання сюди
  ],
};
