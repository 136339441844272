export const module02 = {
  title:
    "§ 2-3. Культура й віра давніх українців. Історичне оповідання Івана Білика «Дарунки скіфів».",
  questions: [
    {
      id: 1,
      question: "1. Богом грому й блискавки язичники вважали",
      options: [
        { id: "А", text: "Ярила" },
        { id: "Б", text: "Перуна" },
        { id: "В", text: "Сварога" },
        { id: "Г", text: "Дажбога" },
      ],
      correctAnswer: "Б",
      isTest: true,
    },
    {
      id: 2,
      question: "2. Київську Русь було хрещено в",
      options: [
        { id: "А", text: "І ст." },
        { id: "Б", text: "V ст." },
        { id: "В", text: "ІХ ст." },
        { id: "Г", text: "Х ст." },
      ],
      correctAnswer: "Г",
      isTest: true,
    },
    {
      id: 3,
      question:
        "3. Землі сучасної України до нашої ери заселяли всі названі етноси, ОКРІМ",
      options: [
        { id: "А", text: "кімерійців" },
        { id: "Б", text: "сарматів" },
        { id: "В", text: "скіфів" },
        { id: "Г", text: "персів" },
      ],
      correctAnswer: "Г",
      isTest: true,
    },
    {
      id: 4,
      question: "4. «Дарунки скіфів» Івана Білика за жанром –",
      options: [
        { id: "А", text: "міф" },
        { id: "Б", text: "легенда" },
        { id: "В", text: "історичне оповідання" },
        { id: "Г", text: "пригодницька повість" },
      ],
      correctAnswer: "В",
      isTest: true,
    },
    {
      id: 5,
      question: "5. З-поміж дарунків скіфського царя НЕ було",
      options: [
        { id: "А", text: "жаби" },
        { id: "Б", text: "миші" },
        { id: "В", text: "зайця" },
        { id: "Г", text: "шуліки" },
      ],
      correctAnswer: "В",
      isTest: true,
    },
    {
      id: 6,
      question:
        "6. Переглянувши відеоматеріал від WAS.media «Знахідка століття. Скіфська пектораль – про що вона розповідає» (5 хв 10 с), розподіліть твердження на дві колонки.",
      isGame: true,
      videoPath: "https://www.youtube.com/embed/5HqX39Lfms8",
      initialWords: [
        {
          id: 1,
          text: "1. Пектораль знайшов археолог Борис Мозолевський.",
          type: "leftBucket",
        },
        {
          id: 2,
          text: "2. Пектораль знайдено в скіфському кургані на Київщині.",
          type: "rightBucket",
        },
        {
          id: 3,
          text: "3. Корінь слова пектораль у перекладі з латинської – груди. ",
          type: "leftBucket",
        },
        {
          id: 4,
          text: "4. Вага золотої пекторалі – 149 грамів.",
          type: "rightBucket",
        },
        {
          id: 5,
          text: "5. Пектораль знайшли в кургані 2300 років тому.",
          type: "rightBucket",
        },
        {
          id: 6,
          text: "6. Прикраса складається з трьох ярусів, що зображають різні світи.",
          type: "leftBucket",
        },
        {
          id: 7,
          text: "7. Світ земного життя представлено на нижньому ярусі. ",
          type: "rightBucket",
        },
        {
          id: 8,
          text: "8. Музичну тишу степу символізують птахи.",
          type: "rightBucket",
        },
        {
          id: 9,
          text: "9. Квіти середнього ярусу були інкрустовані блакитною емаллю.",
          type: "leftBucket",
        },
        {
          id: 10,
          text: "10. Мозолевський вивіз пектораль на грудях під тілогрійкою. ",
          type: "rightBucket",
        },
      ],
      leftBucketTitle: "Правильне твердження",
      rightBucketTitle: "Неправильне твердження",
    },
    // Додати нові питання сюди
  ],
};
