export const module4 = {
  title: "§ 4. Розряди прислівників за значенням",
  questions: [
    {
      id: 1,
      question: "1. Прислівниками є всі слова варіанта",
      options: [
        { id: "А", text: "наввипередки, хутко, раптовий" },
        { id: "Б", text: "колись, спокійніше, звеселившись" },
        { id: "В", text: "навсібіч, зрання, спантеличено" },
        { id: "Г", text: "навшпиньки, весело, збережено" },
      ],
      correctAnswer: "В",
      isTest: true,
    },
    {
      id: 2,
      question: "2. Немає прислівника в реченні",
      options: [
        { id: "А", text: "Перси мусили відходити далеко праворуч." },
        {
          id: "Б",
          text: "Не помітивши небезпеки, ми спустилися в долину.",
        },
        { id: "В", text: "Яскраве світло падало звідкілясь згори." },
        { id: "Г", text: "Небо мовчки засипало землю рясним снігом." },
      ],
      correctAnswer: "В",
      isTest: true,
    },
    {
      id: 3,
      question:
        "3. Правильно утворено ступені порівняння обох прислівників у варіанті",
      options: [
        { id: "А", text: "краще, менш вдаліше" },
        { id: "Б", text: "якнайбільше, слабкіше" },
        { id: "В", text: "найліпше, саме нижче" },
        { id: "Г", text: "найширше, більш тепло" },
      ],
      correctAnswer: "Г",
      isTest: true,
    },
    {
      id: 4,
      question: "4. Другий склад наголошений у прислівнику",
      options: [
        {
          id: "А",
          text: "звисока",
        },
        { id: "Б", text: "затемна" },
        { id: "В", text: "разом" },
        { id: "Г", text: "здалека" },
      ],
      correctAnswer: "Б",
      isTest: true,
    },
    {
      id: 5,
      question: "5. З’ясуйте розряд виділених прислівників.",
      dropdownQuestionParts: [
        "<i> 1) З походу всі хлопці повернулися  ",
        { id: "dropdown1", text: "вдосвіта " },
        "<br /><i> 2) Грицик ",
        { id: "dropdown2", text: "знічев’я " },
        "<i> майстрував калинову сопілку.<br />3) Двері до білої хатини були ",
        { id: "dropdown3", text: "широко " },
        "<i>прочинені.<br />4) ",
        { id: "dropdown4", text: "Двічі " },
        "<i> Грицик рятував Санька з трясовини.<br/></i>",
      ],
      options: [
        { id: "А", text: "причини" },
        { id: "Б", text: "якісно-означальний" },
        { id: "В", text: "способу дії" },
        { id: "Г", text: "міри і ступеня дії" },
        { id: "Д", text: "часу" },
      ],
      correctAnswers: {
        dropdown1: "Д",
        dropdown2: "А",
        dropdown3: "Б",
        dropdown4: "Г",
      },
      showAnswerOptions: true,
      dropdownAmount: 4,
      isTest: false,
    },
    {
      id: 6,
      question: "6. З’ясуйте синтаксичну роль виділених прислівників.",
      dropdownQuestionParts: [
        "<i> 1) Кущ ",
        { id: "dropdown1", text: "праворуч " },
        "<i> був схожий на агрусовий.<br/>2) Попрощавшись, я вийшов ",
        { id: "dropdown2", text: "надвір " },
        "<br /><i>3) У новому будинку було ",
        { id: "dropdown3", text: "затишно " },
        "<br/><i>4) Наше ",
        { id: "dropdown4", text: "завтра " },
        "<i> залежить від сьогодення.</i>",
      ],
      options: [
        { id: "А", text: "підмет" },
        { id: "Б", text: "присудок" },
        { id: "В", text: "означення" },
        { id: "Г", text: "додаток" },
        { id: "Д", text: "обставина" },
      ],
      correctAnswers: {
        dropdown1: "В",
        dropdown2: "Д",
        dropdown3: "Б",
        dropdown4: "А",
      },
      showAnswerOptions: true,
      dropdownAmount: 4,
      isTest: false,
    },
    {
      id: 7,
      question: "7. Виберіть прислівники й перетягніть їх у чарунок.",
      isGame: true,
      initialWords: [
        { id: 1, text: "Будь-хто", type: "rightBucket" },
        { id: 2, text: "будь-куди", type: "leftBucket" },
        { id: 3, text: "по-київському", type: "leftBucket" },
        { id: 4, text: "київський", type: "rightBucket" },
        { id: 5, text: "більший", type: "rightBucket" },
        { id: 6, text: "більше", type: "leftBucket" },
        { id: 7, text: "яка-небудь", type: "rightBucket" },
        { id: 8, text: "як-небудь", type: "leftBucket" },
        { id: 9, text: "менше", type: "leftBucket" },
        { id: 10, text: "менший", type: "rightBucket" },
        { id: 11, text: "довгий", type: "rightBucket" },
        { id: 12, text: "довго", type: "leftBucket" },
        { id: 13, text: "жартувати", type: "rightBucket" },
        { id: 14, text: "жартома", type: "leftBucket" },
        { id: 15, text: "з гори", type: "rightBucket" },
        { id: 16, text: "згори", type: "leftBucket" },
        { id: 17, text: "двоє", type: "rightBucket" },
        { id: 18, text: "двічі", type: "leftBucket" },
        { id: 19, text: "по-вашому", type: "leftBucket" },
        { id: 20, text: "вашому", type: "rightBucket" },
        { id: 21, text: "гурт", type: "rightBucket" },
        { id: 22, text: "гуртом", type: "leftBucket" },
        { id: 23, text: "урукопаш", type: "leftBucket" },
        { id: 24, text: "рученьки", type: "rightBucket" },
      ],
      leftBucketTitle: "Прислівники",
      rightBucketTitle: "Інші частини мови",
    },
  ],
  // Додати нові питання сюди
};
