
export const module02 = {
  title: "Антична література. Давньогрецька міфологія",
  questions: [
    {
      id: 1,
      question: "1. Дослівно «античний» в перекладі з латинської означає",
      options: [
        { id: "А", text: "цінний" },
        { id: "Б", text: "міфічний" },
        { id: "В", text: "божественний" },
        { id: "Г", text: "давній" },
      ],
      correctAnswer: "Г",
      isTest: true,
    },
    {
      id: 2,
      question: "2. Античність охоплює період",
      options: [
        { id: "А", text: "VIII ст. до н. е. — V ст. до н. е." },
        { id: "Б", text: "VIII ст. до н. е. — V ст. н. е." },
        { id: "В", text: "VIII ст. н. е. — V ст. н. е." },
        { id: "Г", text: "V ст. до н. е. — VІІІ ст. н. е." },
      ],
      correctAnswer: "Б",
      isTest: true,
    },
    {
      id: 3,
      question: "3. Античним називають мистецтво",
      options: [
        { id: "А", text: "прадавніх українців" },
        { id: "Б", text: "Давньої Греції" },
        { id: "В", text: "Давньої Греції та Давнього Риму" },
        { id: "Г", text: "Давньої Греції та Давнього Єгипту" },
      ],
      correctAnswer: "В",
      isTest: true,
    },
    {
      id: 4,
      question: "4. У центрі творів античного мистецтва знаходиться",
      options: [
        { id: "А", text: "бог" },
        { id: "Б", text: "держава" },
        { id: "В", text: "людина" },
        { id: "Г", text: "мистецтво" },
      ],
      correctAnswer: "В",
      isTest: true,
    },
    {
      id: 5,
      question: "5. Хто із персонажів є героєм міфів троянського циклу?",
      options: [
        { id: "А", text: "Паріс" },
        { id: "Б", text: "Прометей" },
        { id: "В", text: "Еврісфей" },
        { id: "Г", text: "Геракл" },
      ],
      correctAnswer: "А",
      isTest: true,
    },
    {
      id: 6,
      question: "6. На яку обіцянку спокусився Паріс, присуджуючи яблуко?",
      options: [
        { id: "А", text: "панувати над усіма смертними, що живуть на землі" },
        { id: "Б", text: "ніколи не знати поразки в бою і стати славетним героєм" },
        { id: "В", text: "взяти за дружину найвродливішу жінку" },
        { id: "Г", text: "володіти всіма коштовностями світу" },
      ],
      correctAnswer: "В",
      isTest: true,
    },
    // Додайте нові питання тут
  ],
};
