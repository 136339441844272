import React, { Component } from "react";
import { Carousel } from "react-bootstrap";
import "./Slider.css";
import "./responsive.css";

import slide1 from "../../images/avramenko_02.png";
import slide2 from "../../images/baloon2.png";

class Slider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeIndex: 0,
    };
  }

  handleSelect = (selectedIndex, e) => {
    this.setState({ activeIndex: selectedIndex });
  };

  goToSlide = (index) => {
    this.setState({ activeIndex: index });
  };

  render() {
    const { activeIndex } = this.state;
    return (
      <section className="carousel_bg">
        <div className="slider">
          <div className="headerinfo">
            <div>
              <img src={slide1} alt="slide1" />
            </div>
          </div>
          {/* <Carousel
            activeIndex={activeIndex}
            onSelect={this.handleSelect}
            controls={false}
            indicators={false}
          >
            <Carousel.Item interval={1500}>
              <img className="silde" alt="slide1" src={slide1} />
            </Carousel.Item>
            <Carousel.Item interval={1500}>
              <img className="silde" alt="slide2" src={slide2} />
            </Carousel.Item>
          </Carousel>
        </div>

        <div className="custom-controls">
          <div
            className={`control-dot ${activeIndex === 0 ? "active" : ""}`}
            onClick={() => this.goToSlide(0)}
          >
            1
          </div>
          <div
            className={`control-dot ${activeIndex === 1 ? "active" : ""}`}
            onClick={() => this.goToSlide(1)}
          >
            2
          </div> */}
        </div>
      </section>
    );
  }
}

export default Slider;
