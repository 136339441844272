import { text_mod_19, text_mod_20, text_mod_21 } from "../text/Text";

export const module07 = {
  title: "Заповідь любові: Біблія та українська література. Біблія – одна зі священних книг людства. «Євангеліє від Матвія»",
  questions: [
    {
      id: 1,
      subtitle: "ДІЗНАЙСЯ БІЛЬШЕ",
      onlyText: true,
      text: text_mod_19, },

      {
        id: 2,
        subtitle: "ДОСЛІДЖУЄМО МЕДІА",
        onlyText: true,
        text: text_mod_20, },

        {
          id: 3,
          subtitle: "ЧИТАЦЬКЕ ДОЗВІЛЛЯ",
          onlyText: true,
          text: text_mod_21, },
    {
      id: 4,
      question: "1. Яке з тверджень про Біблію є НЕПРАВИЛЬНИМ?",
      options: [
        { id: "А", text: "Біблія нараховує 66 книг (39 – у Старому Заповіті, 27 – у Новому)." },
        { id: "Б", text: "Тексти Біблії творилися у трьох частинах світу – в Азії, Африці, Європі приблизно 60-ма поколіннями авторів." },
        { id: "В", text: "Загальна побудова Святого Письма є непослідовною." },
        { id: "Г", text: "Біблія вирізняється жанровим розмаїттям." },
      ],
      subtitle: "Тестові питання",
      correctAnswer: "В",
      isTest: true,
    },
    {
      id: 5,
      question: "2. Найпоширенішим жанром Біблії є",
      options: [
        { id: "А", text: "повчання" },
        { id: "Б", text: "послання" },
        { id: "В", text: "псалом" },
        { id: "Г", text: "притча" },
      ],
      correctAnswer: "Г",
      isTest: true,
    },
    {
      id: 6,
      question: "3. Найважливішою частиною Старого Заповіту є",
      options: [
        { id: "А", text: "розповідь про створення світу." },
        { id: "Б", text: "десять заповідей Божих." },
        { id: "В", text: "розповідь про прабатьківський гріх." },
        { id: "Г", text: "розповідь про союз Бога з народом Ізраїля." },
      ],
      correctAnswer: "Б",
      isTest: true,
    },
    {
      id: 7,
      question: "4. Яка із заповідей Божих є основною?",
      options: [
        { id: "А", text: "“Люби Господа Бога свого всім серцем своїм, і всією душею своєю, і всією своєю думкою”." },
        { id: "Б", text: "“Люби свого ближнього, як само́го себе́”." },
        { id: "В", text: "“Бог є любов”" },
        { id: "Г", text: "“І як бажаєте, щоб вам люди чинили, так само чиніть їм і ви”." },
      ],
      correctAnswer: "А",
      isTest: true,
    },
    {
      id: 8,
      question: "5. У якому із Євангелій описано народження Ісуса Христа?",
      options: [
        { id: "А", text: "від Матвія" },
        { id: "Б", text: "від Марка" },
        { id: "В", text: "від Луки" },
        { id: "Г", text: "від Івана" },
      ],
      correctAnswer: "А",
      isTest: true,
    },
    {
      id: 9,
      question: "6. На якій із реліквій присягають президенти України під час інавгурації?",
      options: [
        { id: "А", text: "Остромировому Євангелії" },
        { id: "Б", text: "Реймському Євангелії" },
        { id: "В", text: "Пересопницькому Євангелії" },
        { id: "Г", text: "Острозькій Біблії" },
      ],
      correctAnswer: "В",
      isTest: true,
    },
    // Додати нові питання сюди
  ],
};
