export const module6 = {
  title: "§ 6. Написання прислівників",
  questions: [
    {
      id: 1,
      question: "1. З дефісом треба писати виділений прислівник у варіанті",
      options: [
        {
          id: "А",
          text: 'Греки <span class="text-wow">&nbsp;по/південному&nbsp;</span> запально поклялись допомогти скіфам.',
        },
        {
          id: "Б",
          text: 'Зачаровані діти біля батьків звелися <span class="text-wow">&nbsp;на/вшпиньки</span>.',
        },
        {
          id: "В",
          text: '<span class="text-wow">Право/руч&nbsp;</span> виднілися поодинокі розлогі кущі.',
        },
        {
          id: "Г",
          text: '<span class="text-wow">&nbsp;по/переду&nbsp;</span> стривожено застрекотіла сорока.',
        },
      ],
      correctAnswer: "А",
      isTest: true,
    },
    {
      id: 2,
      question:
        "2. Окремо треба писати всі прислівникові сполучення у варіанті.",
      options: [
        { id: "А", text: "без/відома, по/двоє, по/їхньому" },
        { id: "Б", text: "на/диво, до/ладу, коли/не/коли" },
        { id: "В", text: "сам/на/сам, на/льоту, за/кордон" },
        { id: "Г", text: "по/змозі, на/сміх, на/в/перебій" },
      ],
      correctAnswer: "В",
      isTest: true,
    },
    {
      id: 3,
      question: "3. Разом треба писати всі прислівники у варіанті",
      options: [
        { id: "А", text: "на/весні, до/дому, врешті/решт" },
        { id: "Б", text: "в/поперек, що/вечора, де/не/де" },
        { id: "В", text: "з/середини, на/всібіч, по/заячому" },
        { id: "Г", text: "над/вечір, аби/куди, в/нічию" },
      ],
      correctAnswer: "Г",
      isTest: true,
    },
    {
      id: 4,
      question:
        '4. Дві букви <span class="text-wow"> НН </span> треба писати в усіх прислівниках варіанта',
      options: [
        {
          id: "А",
          text: "спросон..я, навман..я, невпин..о",
        },
        { id: "Б", text: "попідтин..ю, сумлін..о, несподіван..о" },
        { id: "В", text: "негадан..о, по-родин..ому, зорян..о" },
        { id: "Г", text: "зран..я, попідвікон..ю, неждан..о" },
      ],
      correctAnswer: "А",
      isTest: true,
    },
    {
      id: 5,
      question:
        '5. Букву  <span class="text-wow"> і &nbsp;</span> треба писати в кінці кожного прислівника у варіанті',
      options: [
        {
          id: "А",
          text: "поодинц.., безвіст..",
        },
        { id: "Б", text: "насторож.., почаст.." },
        { id: "В", text: "поноч.., по-котяч.." },
        { id: "Г", text: "по-латин.., утрич.." },
      ],
      correctAnswer: "Г",
      isTest: true,
    },
    {
      id: 6,
      question:
        '6. <span class="tekst-wow"> ПРАВИЛЬНО&nbsp;</span> написано прислівник у рядку',
      options: [
        {
          id: "А",
          text: "По переду забовваніли дерева Вербової балки.",
        },
        { id: "Б", text: "Верхові дуже повільно їхали назустріч." },
        { id: "В", text: "Дарій по царському віддячить кожному!" },
        { id: "Г", text: "Коли не коли дівчата весело виглядали у вікно." },
      ],
      correctAnswer: "Б",
      isTest: true,
    },
    {
      id: 7,
      question:
        "7. Розподіліть прислівники за написанням у три чарунки: <br>1) Разом; 2) Окремо; 3) З дефісом.",
      isGame: true,
      initialWords: [
        { id: 1, text: "На/жаль", type: "centerBucket" },
        { id: 2, text: "що/року", type: "leftBucket" },
        { id: 3, text: "ледве/ледве", type: "rightBucket" },
        { id: 4, text: "у/тричі", type: "leftBucket" },
        { id: 5, text: "до/речі", type: "centerBucket" },
        { id: 6, text: "по/моєму", type: "rightBucket" },
        { id: 7, text: "без/сумніву", type: "centerBucket" },
        { id: 8, text: "аби/коли", type: "leftBucket" },
        { id: 9, text: "пліч/о/пліч", type: "rightBucket" },
        { id: 10, text: "раз/у/раз", type: "centerBucket" },
        { id: 11, text: "як/найкраще", type: "leftBucket" },
        { id: 12, text: "до/вподоби", type: "centerBucket" },
        { id: 13, text: "хтозна/як", type: "rightBucket" },
        { id: 14, text: "поза/очі", type: "leftBucket" },
        { id: 15, text: "так/от", type: "rightBucket" },
        { id: 16, text: "де/куди", type: "leftBucket" },
        { id: 17, text: "по/людському", type: "rightBucket" },
        { id: 18, text: "з/розгону", type: "centerBucket" },
      ],
      leftBucketTitle: "Разом",
      centerBucketTitle: "Окремо",
      rightBucketTitle: "З дефісом",
      bucketsAmount: 3,
    },
    // Додати нові питання сюди
  ],
};
