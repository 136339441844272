export const module06 = {
  title: "§ 10-11. Біблія – одна зі священних книг.",
  questions: [
    {
      id: 1,
      question: "1. НЕПРАВИЛЬНИМ є твердження",
      options: [
        { id: "А", text: "Біблія складається із Старого та Нового Заповіту" },
        { id: "Б", text: "Святе Письмо складається із творів різних жанрів" },
        {
          id: "В",
          text: "написання Біблії тривало з ХІІ ст. до н. е. по ІІ ст. н. е.",
        },
        {
          id: "Г",
          text: "Обидві частини Біблії написали Лука, Матвій, Марк та Іван",
        },
      ],
      correctAnswer: "Г",
      isTest: true,
    },
    {
      id: 2,
      question: "2. У Біблії НЕМАЄ розповіді про",
      options: [
        {
          id: "А",
          text: "створення землі, неба, тварин і перших людей – Адама і Єви",
        },
        { id: "Б", text: "вихід єврейського народу з єгипетської неволі" },
        { id: "В", text: "створення Русі та її столиці Києва" },
        { id: "Г", text: "всесвітній потоп і Ноїв ковчег" },
      ],
      correctAnswer: "В",
      isTest: true,
    },
    {
      id: 3,
      question: "3. НЕПРАВИЛЬНИМ є твердження",
      options: [
        {
          id: "А",
          text: "Новий Заповіт розпочинається із чотирьох оповідей про життя Ісуса Христа",
        },
        {
          id: "Б",
          text: "євангеліст Матвій був митарем, одним із дванадцяти учнів Ісуса Христа",
        },
        { id: "В", text: "біблійні твори містять морально-етичні орієнтири" },
        { id: "Г", text: "свої твори апостол Матвій написав у V ст. до н. е." },
      ],
      correctAnswer: "Г",
      isTest: true,
    },
    {
      id: 4,
      question: "4. Перша і найбільша заповідь у Євангелії від Матвія –",
      options: [
        { id: "А", text: "Шануй батька свого й матір свою." },
        { id: "Б", text: "Не свідчи неправдиво на ближнього твого." },
        { id: "В", text: "Полюби Господа, Бога твого, усім серцем твоїм." },
        {
          id: "Г",
          text: "Не пожадай нічого того, що є власністю ближнього твого.",
        },
      ],
      correctAnswer: "В",
      isTest: true,
    },
    {
      id: 5,
      question: "5. Увідповідніть фразеологізми з їхніми значення.",
      dropdownQuestionParts: [
        "<i> 1. «Вовк в овечій шкурі» <br />",
        { id: "dropdown1" },
        "<br /><i> 2. «Хома невірний» <br />",
        { id: "dropdown2" },
        "<br /> <i>3. «Блудний син».<br />",
        { id: "dropdown3" },
        "<br /> <i>4. «Сіль землі». <br />",
        { id: "dropdown4" },
      ],
      options: [
        { id: "найважливіші люди суспільства", text: " " },
        { id: "людина, що розкаялася у своїх гріхах", text: " " },
        { id: "лицемір, що приховує злі наміри ", text: " " },
        { id: "особа, яка безкорисливо допомагає ближньому", text: " " },
        { id: "людина, яка сумнівається, не вірить у що-небудь ", text: "  " },
      ],
      correctAnswers: {
        dropdown1: "лицемір, що приховує злі наміри ",
        dropdown2: "людина, яка сумнівається, не вірить у що-небудь ",
        dropdown3: "людина, що розкаялася у своїх гріхах",
        dropdown4: "найважливіші люди суспільстваА",
      },
      showAnswerOptions: false,
      dropdownAmount: 4,
      isTest: false,
    },
    {
      id: 6,
      question:
        "6. Розподіліть фразеологізми за походженням. </br><b><i style='text-align: center; display: block;'>Фразеологізми: </i></b>",
      isGame: true,

      initialWords: [
        { id: 1, text: "дамоклів меч", type: "rightBucket" },
        { id: 2, text: "авгієві стайні", type: "rightBucket" },
        { id: 3, text: "вавилонське стовпотворіння", type: "leftBucket" },
        { id: 4, text: "іти на Голгофу", type: "leftBucket" },
        { id: 5, text: "гордіїв вузол", type: "rightBucket" },
        { id: 6, text: "поцілунок Іуди", type: "leftBucket" },
        { id: 7, text: "прокрустове ложе ", type: "rightBucket" },
        { id: 8, text: "нести свій хрест", type: "leftBucket" },
      ],
      leftBucketTitle: "Фразеологізми біблійного походження",
      rightBucketTitle: "Фразеологізми античного походження",
    },
    {
      id: 7,
      question:
        "7. Переглянувши анімаційне відео «Літературні стилі в Біблії» (6 хв 28 с), законспектуйте здобуту інформацію у вигляді таблиці.",
      videoPath:
        "https://www.youtube.com/embed/3-BNieWjN0k?si=oa6w95R0gU6TcoS6",
      addClass: "horizontal",
      isTest: true,
      isCreativeTask: true,
    },

    // Додати нові питання сюди
  ],
};
