export const module03 = {
  title: "Гомер «Іліада»",
  questions: [
    {
      id: 1,
      question: "1. Які проблеми розглядає «гомерівське питання»?",
      options: [
        { id: "А", text: "авторство поем «Іліада», «Одіссея» та достовірність існування самого автора" },
        { id: "Б", text: "проблему створення та існування міфів" },
        { id: "В", text: "особливості античної історії та мистецтва" },
        { id: "Г", text: "проблеми морального вибору" },
      ],
      correctAnswer: "А",
      isTest: true,
    },
    {
      id: 2,
      question: "2. До якого жанру належить «Іліада»?",
      options: [
        { id: "А", text: "міф" },
        { id: "Б", text: "легенда" },
        { id: "В", text: "історичне оповідання" },
        { id: "Г", text: "епічна поема" },
      ],
      correctAnswer: "Г",
      isTest: true,
    },
    {
      id: 3,
      question: "3. Про який епізод Троянської війни розповідає Гомер в «Іліаді»?",
      options: [
        { id: "А", text: "початок війни" },
        { id: "Б", text: "причини війни" },
        { id: "В", text: "десятий рік війни" },
        { id: "Г", text: "завершення війни" },
      ],
      correctAnswer: "В",
      isTest: true,
    },
    {
      id: 4,
      question: "4. Вчинок якого царя став причиною гніву Ахілла?",
      options: [
        { id: "А", text: "Пріама" },
        { id: "Б", text: "Менелая" },
        { id: "В", text: "Енея" },
        { id: "Г", text: "Агамемнона" },
      ],
      correctAnswer: "Г",
      isTest: true,
    },
    {
      id: 5,
      question: "5. Винайдення якого віршового розміру приписують Гомеру?",
      options: [
        { id: "А", text: "ямб" },
        { id: "Б", text: "анапест" },
        { id: "В", text: "хорей" },
        { id: "Г", text: "гекзаметр" },
      ],
      correctAnswer: "Г",
      isTest: true,
    },
    {
      id: 6,
      question: "6. Чому Гомер назвав свою поему «Іліадою?»",
      options: [
        { id: "А", text: "за іменем головного героя" },
        { id: "Б", text: "за назвою головної битви" },
        { id: "В", text: "за назвою місцевості, де відбувалася головна битва" },
        { id: "Г", text: "за назвою міста, яке мали завоювати" },
      ],
      correctAnswer: "Г",
      isTest: true,
    },
    // Додайте нові питання тут
  ],
};
