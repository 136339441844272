import React from "react";
import "./Baloon.css";
import "./responsive.css";


class Baloon extends React.Component {
  render() {
    const { imgSrc, link, text } = this.props;

    return (
      <div className="baloon-circle">
        <div className="baloon-square">
          <img src={imgSrc} alt="baloon item" />
          <a href={link} className="baloon-item">
            <p>{text}</p>
          </a>
        </div>
      </div>
    );
  }
}

export default Baloon;
