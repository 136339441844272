import {text_mod_1, } from "../text/Text.js";

export const module01 = {

  title: "Урок 1-2. Життєве самовизначення. Складники життєвого самовизначення. Соціальні статуси та ролі. Цінність родини.",
  questions: [
    {
      id: 1,
      onlyText: true,
      text: text_mod_1, },
    {
      id: 2,
      question:
        "Знайдіть відповідність між поняттям та його визначенням",
      isGame: true,

      initialWords: [
        {
          id: 1,
          text: "це відкриття себе, виявлення передусім своїх позитивних якостей і можливостей, а також тих задатків, які потім шляхом самовиховання людина зможе перетворити на здібності, а талант — на стійку рису характеру.",
          type: "leftBucket",
        },
        {
          id: 2,
          text: "це процес відкриття себе, пізнання свого внутрішнього світу, сильних і слабких рис своєї особистості.",
          type: "centerBucket",
        },
        {
          id: 3,
          text: "це свідома систематична робота особистості над собою з метою усунення тих чи інших недоліків і формування позитивних якостей, що відповідають вимогам суспільства й особистого плану розвитку.",
          type: "rightBucket",
        },
      ],
      leftBucketTitle: "Самовизначення",
      centerBucketTitle: "Самопізнання",
      rightBucketTitle: "Самовиховання",
      bucketsAmount: 3,
    },

    {
      id: 3,
      question:
        "Знайдіть відповідність між поняттям та його визначенням",
      isGame: true,

      initialWords: [
        {
          id: 1,
          text: "Усвідомлення свого місця та призначення в житті.",
          type: "leftBucket",
        },
        {
          id: 2,
          text: "Розуміти себе та свої можливості у професії.",
          type: "rightBucket",
        },
        {
          id: 3,
          text: "Вибір професії, яка відповідає моїм потребам та талантам.",
          type: "rightBucket",
        },
        {
          id: 4,
          text: "Усвідомлення життєвих цінностей та пріоритетів.",
          type: "leftBucket",
        },
      ],
      leftBucketTitle: "Життєве самовизначення",
      rightBucketTitle: "Професійне самовизначення",
    },

    {
      id: 3,
      question:
        "Знайдіть відповідність між поняттям та його визначенням",
      isGame: true,

      initialWords: [
        {
          id: 1,
          text: "Розуміння своїх сильних і слабких сторін, планування життя.",
          type: "leftBucket",
        },
        {
          id: 2,
          text: "Визначення своїх соціальних зв'язків та внеску у розвиток спільноти.",
          type: "rightBucket",
        },
        {
          id: 3,
          text: "Визначення своєї ролі в суспільстві.",
          type: "rightBucket",
        },
        {
          id: 4,
          text: "Ставлення до власного самовизначення відповідально.",
          type: "leftBucket",
        },

        {
          id: 5,
          text: "Розвиток потрібних для різних соціальних ролей життєвих навичок.",
          type: "rightBucket",
        },
        {
          id: 6,
          text: "Активна громадянська позиція, доброчинна діяльність",
          type: "rightBucket",
        },
      ],
      leftBucketTitle: "Особистісне самовизначення",
      rightBucketTitle: "Соціальне самовизначення",
    },
    
    // Додати нові питання сюди
  ],
};
