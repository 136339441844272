export const module22 = {
  title: "Франческо Петрарка. Сонети",
  questions: [
    {
      id: 1,
      question: "1. Яку роль відіграв Франческо Петрарка в європейській культурі?",
      options: [
        { id: "А", text: "був засновником нової сучасної поезії" },
        { id: "Б", text: "написав 'Божественну комедію'" },
        { id: "В", text: "створив фреску 'Парнас'" },
        { id: "Г", text: "винайшов віршову форму сонета" },
      ],
      correctAnswer: "А",
      isTest: true,
    },
    {
      id: 2,
      question: "2. Коли і де Петрарка вперше зустрів Лауру?",
      options: [
        { id: "А", text: "У 1321 році у Флоренції" },
        { id: "Б", text: "У 1327 році в церкві Святої Клари" },
        { id: "В", text: "У 1348 році під час епідемії чуми" },
        { id: "Г", text: "У 1304 році в Ареццо" },
      ],
      correctAnswer: "Б",
      isTest: true,
    },
    {
      id: 3,
      question: "3. Яка основна тема сонетів Петрарки?",
      options: [
        { id: "А", text: "політична критика" },
        { id: "Б", text: "релігійні роздуми" },
        { id: "В", text: "опис природи" },
        { id: "Г", text: "кохання до Лаури" },
      ],
      correctAnswer: "Г",
      isTest: true,
    },
    {
      id: 4,
      question: "4. Що символізує 'вогонь, що серце пік' у Сонеті 61?",
      options: [
        { id: "А", text: "хворобу" },
        { id: "Б", text: "страждання від кохання" },
        { id: "В", text: "гнів" },
        { id: "Г", text: "релігійний запал" },
      ],
      correctAnswer: "Б",
      isTest: true,
    },
    {
      id: 5,
      question: "5. Яку емоцію передає бліде обличчя коханої у Сонеті 123?",
      options: [
        { id: "А", text: "гнів" },
        { id: "Б", text: "страх" },
        { id: "В", text: "хвилювання від зустрічі з коханим" },
        { id: "Г", text: "байдужість" },
      ],
      correctAnswer: "В",
      isTest: true,
    },
    {
      id: 6,
      question: "6. Яку мету ставить перед собою ліричний герой у Сонеті 131?",
      options: [
        { id: "А", text: "забути кохану" },
        { id: "Б", text: "пробудити нове кохання в собі" },
        { id: "В", text: "викликати співчуття у коханої" },
        { id: "Г", text: "помститися за нерозділене кохання" },
      ],
      correctAnswer: "В",
      isTest: true,
    },
    // Додайте нові питання тут
  ],
};
