export const module03 = {
  title: "Порядок слів у реченні. Логічний наголос.",
  questions: [
    {
      id: 1,
      question: "1. Слова, з яких складаються речення, розташовані в певному порядку. Розрізняють такі типи порядку слів у реченні:",
      options: [
        { id: "А", text: "прямий та переносний" },
        { id: "Б", text: "прямий та зворотний" },
        { id: "В", text: "зворотний" },
        { id: "Г", text: "направлений" },
      ],
      correctAnswer: "А",
      isTest: true,
    },
    {
      id: 2,
      question:  "2. Оберіть правильну відповідь.",
      dropdownQuestionParts: [
        "У реченнях з прямим порядком слів ",
        { id: "dropdown1",},
        " стоїть перед ",
        { id: "dropdown2",},
        ",",
        { id: "dropdown3",},
        " – перед означуваним словом, ",
        { id: "dropdown4",},
        " після слова, від якого він залежить, а ",
        { id: "dropdown5",},
        " прироможуть займати різні місця, зважаючи на своє значення і спосіб вираження.",
      ],
      options: [
        { id: "А", text: "додаток" },
        { id: "Б", text: "обставина" },
        { id: "В", text: "присудок" },
        { id: "Г", text: "означення" },
        { id: "Д", text: "підмет" },
      ],
      correctAnswers: {
        dropdown1: "Д",
        dropdown2: "В",
        dropdown3: "Г",
        dropdown4: "А",
        dropdown5: "Б",
      },
      showAnswerOptions: true,
      dropdownAmount: 5,
      isTest: false,
    },
    // Додайте нові питання тут
  ],
};
