const paths = {
  "Українська мова": "ukrmova",
  "Українська література": "ukrlit",
  Математика: "math",
  Геометрія: "geometry",
  "Українська та зарібужна література": "int",
  "Здоров'я, безпека та добробут": "zbd",
};

export default paths;
