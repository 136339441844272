import { Component } from "react";
import Slider from "react-slick";
import Baloon from "./baloon/Baloon";

import "./LessonSliderPreview.css";
import "./responsive.css";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import heading from "../../images/heading.png";
import slide1 from "../../images/placeholder_150_150.png";

class LessonSliderPreview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      display: true,
      // width: 1400,
    };
  }

  // increaseWidth = () => {
  //   this.setState((prevState) => ({ width: prevState.width + 100 }));
  // };

  // decreaseWidth = () => {
  //   this.setState((prevState) => ({ width: prevState.width - 100 }));
  // };

  // toggleDisplay = () => {
  //   this.setState((prevState) => ({ display: !prevState.display }));
  // };

  render() {
    const { display, width } = this.state;
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      autoplaySpeed: 2000,
      autoplay: true,
      slidesToShow: 4,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 479,
          settings: {
            slidesToShow: 1,
            dots: false,
          },
        },
        {
          breakpoint: 990,
          settings: {
            slidesToShow: 2,
            dots: false,
          },
        },
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 3,
            dots: false,
          },
        },
        {
          breakpoint: 1400,
          settings: {
            slidesToShow: 3,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 2,
          },
        },
      ],
    };

    return (
      <section className="gap lesson-slider-baloon">
        <div className="container">
          <div className="heading">
            <img alt="heading" src={heading} />
            <h2>ОБИРАЙ ВПРАВИ</h2>
            <p>Обирай вправи для перевірки знань!</p>
          </div>

          <div className="baloon-slider">
            <div className="slider-container">
              <div
                className="slider-content"
                style={{
                  display: display ? "block" : "none",
                }}
              >
                <Slider {...settings}>
                  <div>
                    <Baloon imgSrc={slide1} link="#" text="Знайди їжачка" />
                  </div>
                  <div>
                    <Baloon imgSrc={slide1} link="#" text="Перегони мурашок" />
                  </div>
                  <div>
                    <Baloon imgSrc={slide1} link="#" text="Злови метелика" />
                  </div>
                  <div>
                    <Baloon imgSrc={slide1} link="#" text="Веселі м'ячики" />
                  </div>
                  <div>
                    <Baloon imgSrc={slide1} link="#" text="Знайди їжачка" />
                  </div>
                  <div>
                    <Baloon imgSrc={slide1} link="#" text="Порахуй яблука" />
                  </div>
                </Slider>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default LessonSliderPreview;
