import React, { useState } from "react";
import "./FeedbackBanner.css";

function FeedbackBanner({ feedback, closeFeedback }) {
  let text = "Молодець! Правильно!";
  if (feedback !== "correct") {
    text = "Ти можеш краще! Спробуй ще!";
  }

  // function closeFeedback() {
  //   setVisible(false);
  // }

  // if (!visible) {
  //   return null;
  // }

  return (
    <div className={`feedback ${feedback}`} onClick={closeFeedback}>
      {text}
    </div>
  );
}
export default FeedbackBanner;
