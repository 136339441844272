import { Component } from "react";
import Header from "../header_h/Header";

import Footer from "../footer/Footer";

import TheoryContainer from "./theory-container/TheoryContainer";

class TheoryPage extends Component {
  render() {
    const { userName } = this.props;

    const { content } = this.props;

    return (
      <>
        <Header userName={userName} />
        <TheoryContainer content={content} />
        <Footer />
      </>
    );
  }
}

export default TheoryPage;
