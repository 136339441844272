export const module17 = {
  title: "Західноєвропейська середньовічна поезія. Лірика вагантів",
  questions: [
    {
      id: 1,
      question: "1. Яка основна ідея 'Ордену вагантів'?",
      options: [
        { id: "А", text: "Суворе дотримання церковних правил" },
        { id: "Б", text: "Відмова від земних радощів" },
        { id: "В", text: "Прийняття всіх людей незалежно від їх статусу" },
        { id: "Г", text: "Накопичення багатства і влади" },
      ],
      correctAnswer: "В",
      isTest: true,
    },
    {
      id: 2,
      question: "2. Як орден вагантів ставиться до матеріальних благ?",
      options: [
        { id: "А", text: "Повністю відмовляється від них" },
        { id: "Б", text: "Цінує їх понад усе" },
        { id: "В", text: "Надає перевагу простому життю, але не відмовляється від задоволень" },
        { id: "Г", text: "Вимагає від членів ордену віддати все своє майно" },
      ],
      correctAnswer: "В",
      isTest: true,
    },
    {
      id: 3,
      question: "3. Яке ставлення ордену до різноманітності його учасників?",
      options: [
        { id: "А", text: "Приймають лише молодих і здорових" },
        { id: "Б", text: "Їм імпонують різноманітність і люди різного віку та стану" },
        { id: "В", text: "Надають перевагу лише вченим та духовенству" },
        { id: "Г", text: "Приймають тільки бідних і знедолених" },
      ],
      correctAnswer: "Б",
      isTest: true,
    },
    {
      id: 4,
      question: "4. Яке правило ордену щодо раннього підйому?",
      options: [
        { id: "А", text: "Вимагають вставати на світанку" },
        { id: "Б", text: "Забороняють прокидатися рано" },
        { id: "В", text: "Залишають це на розсуд кожного учасника" },
        { id: "Г", text: "Піднімаються рано тільки для молитви" },
      ],
      correctAnswer: "В",
      isTest: true,
    },
    {
      id: 5,
      question: "5. Як орден ставиться до азартних ігор?",
      options: [
        { id: "А", text: "Суворо забороняє їх" },
        { id: "Б", text: "Ігнорує їх існування" },
        { id: "В", text: "Розглядає їх як спосіб перерозподілу майна між членами" },
        { id: "Г", text: "Дозволяє грати лише на великі суми" },
      ],
      correctAnswer: "В",
      isTest: true,
    },
    {
      id: 6,
      question: "6. Яку мету переслідує орден, співаючи пісні про гріхи?",
      options: [
        { id: "А", text: "Заохочення грішної поведінки" },
        { id: "Б", text: "Розваги для публіки" },
        { id: "В", text: "Розрізнення чесних та нечесних людей" },
        { id: "Г", text: "Покаяння у власних гріхах" },
      ],
      correctAnswer: "Г",
      isTest: true,
    },
    // Додайте нові питання тут
  ],
};
