export const module05 = {
  title: "Давньогрецька лірика. Поезія Сафо",
  questions: [
    {
      id: 1,
      question: "1. Як виник термін «лірика»?",
      options: [
        { id: "А", text: "від назви жанру" },
        { id: "Б", text: "від імені античної героїні" },
        { id: "В", text: "від імені античної поетеси" },
        { id: "Г", text: "від назви музичного інструмента" },
      ],
      correctAnswer: "Г",
      isTest: true,
    },
    {
      id: 2,
      question: "2. До жанрів античної лірики НЕ належить?",
      options: [
        { id: "А", text: "елегії" },
        { id: "Б", text: "гекзаметри" },
        { id: "В", text: "ямби" },
        { id: "Г", text: "хори" },
      ],
      correctAnswer: "Б",
      isTest: true,
    },
    {
      id: 3,
      question: "3. При храмі якої богині Сафо заснувала музично-поетичну студію?",
      options: [
        { id: "А", text: "Афіни Паллади" },
        { id: "Б", text: "Афродіти" },
        { id: "В", text: "Артеміди" },
        { id: "Г", text: "Гери" },
      ],
      correctAnswer: "Б",
      isTest: true,
    },
    {
      id: 4,
      question: "4. До якого різновиду лірики належить поезія Сафо?",
      options: [
        { id: "А", text: "елегійна" },
        { id: "Б", text: "ямбічна" },
        { id: "В", text: "сольна" },
        { id: "Г", text: "хорова" },
      ],
      correctAnswer: "В",
      isTest: true,
    },
    {
      id: 5,
      question: "5. «Десятою музою» Сафо назвав",
      options: [
        { id: "А", text: "Аполлон" },
        { id: "Б", text: "Есхіл" },
        { id: "В", text: "Платон" },
        { id: "Г", text: "Гомер" },
      ],
      correctAnswer: "В",
      isTest: true,
    },
    {
      id: 6,
      question: "6. Провідний мотив вірша «До богів подібний…» -",
      options: [
        { id: "А", text: "страждання від нерозділеного кохання" },
        { id: "Б", text: "зображення стану пристрасно закоханої людини" },
        { id: "В", text: "зрада коханої людини" },
        { id: "Г", text: "утвердження кохання як найпрекраснішого почуття" },
      ],
      correctAnswer: "Б",
      isTest: true,
    },
    // Додайте нові питання тут
  ],
};
