export const module04 = {
  title: "Порівняльний зворот. Розділові знаки при ньому.",
  questions: [
    {
      id: 1,
      question: "1. Порівняльний зворот – це сполучення слів, що образно розкриває, уточнює зміст слова чи словосполучення шляхом порівняння і приєднується порівняльними сполучниками як, мов, мовби, немов, немовби, наче, неначе, неначебто, неначеб, ніби, нібито.",
      options: [
        { id: "А", text: "Правда" },
        { id: "Б", text: "Неправда" },
      ],
      correctAnswer: "А",
      isTest: true,
    },
    {
      id: 2,
      question: "2. Оберіть речення, у якому порівняльний зворот не відокремлюється комами (розділові знаки відсутні).",
      options: [
        { id: "А", text: "В її репертуарі більш як 50 партій в операх, оперетах і музичних казках." },
        { id: "Б", text: "Пісня то гриміла як цілий оркестр то коливалась на тонісінькій струні." },
      ],
      correctAnswer: "А",
      isTest: true,
    },
    {
      id: 3,
      question: "3. Порівняння – засіб виразності мовлення, що дає змогу яскравіше й цікавіше зобразити предмет мовлення",
      options: [
        { id: "А", text: "Правда" },
        { id: "Б", text: "Неправда" },
      ],
      correctAnswer: "А",
      isTest: true,
    },
    // Додайте нові питання тут
  ],
};
