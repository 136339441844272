export const module05 = {
  title: "Речення поширені й непоширені. Святий обов’язок – Вітчизну захищати.",
  questions: [
    {
      id: 1,
      question:  "1. Оберіть правильну відповідь.",
      dropdownQuestionParts: [
        { id: "dropdown1",},
        "  - це просте речення з другорядними членами. ",
        { id: "dropdown2",},     
        "   - це просте речення без другорядних членів.",
      ],
      options: [
        { id: "А", text: "Поширене" },
        { id: "Б", text: "Непоширене" },
      ],
      correctAnswers: {
        dropdown1: "А",
        dropdown2: "Б",
      },
      showAnswerOptions: true,
      dropdownAmount: 2,
      isTest: false,
    },
    {
      id: 2,
      question: "2. У поданому реченні наявні головні та другорядні члени речення. Речення ускладнене дієприслівниковим зворотом, однорідними членами речення. Мужні й незламні захисники України, не шкодуючи власного життя, самовіддано боронять рідну землю від жорстокого ворога.",
      options: [
        { id: "А", text: "Правда" },
        { id: "Б", text: "Неправда" },
      ],
      correctAnswer: "А",
      isTest: true,
    },
    // Додайте нові питання тут
  ],
};
