// src/modulesData.js
export const modulesData = {
  8: {
    ukrmova: {
      avr: [
        { path: "module01", title: "Завдання до 01" },
        { path: "module02", title: "Завдання до 02" },
        { path: "module03", title: "Завдання до 03" },
        { path: "module2", title: "Завдання до §2" },
        { path: "module3", title: "Завдання до §3" },
        { path: "module4", title: "Завдання до §4" },
        { path: "module5", title: "Завдання до §5" },
        { path: "module6", title: "Завдання до §6" },
        { path: "module7", title: "Завдання до §7" },
        { path: "module8", title: "Завдання до §8" },
        { path: "module9", title: "Завдання до §9" },
        { path: "module18_19", title: "Завдання до §18-19" },
        { path: "module20_21", title: "Завдання до §20-21" },
        { path: "module23", title: "Завдання до §23" },
      ],

      gol: [
        { path: "module01", title: "Завдання до §1" },
        { path: "module02", title: "Завдання до §2" },
        { path: "module03", title: "Завдання до §3" },
        { path: "module04", title: "Завдання до §4" },
        { path: "module05", title: "Завдання до §5" },
      ],
    },
    ukrlit: {
      avr: [
        { path: "module01", title: "Завдання до §1" },
        { path: "module02", title: "Завдання до §2-3" },
        { path: "module03", title: "Завдання до §4-5" },
        { path: "module04", title: "Завдання до §6-7" },
        { path: "module05", title: "Завдання до §8-9" },
        { path: "module06", title: "Завдання до §10-11" },
        { path: "module07", title: "Завдання до $12-14" },
        { path: "module08", title: "Завдання до §15" },
        { path: "module09", title: "Завдання до §16-17" },
        { path: "module10", title: "Завдання до §18" },
        { path: "module11", title: "Завдання до §19-20" },
      ],
      yac: [
        { path: "module01", title: "Завдання до §1" },
        { path: "module02", title: "Завдання до §2" },
        { path: "module03", title: "Завдання до §3" },
        { path: "module04", title: "Завдання до §4" },
        { path: "module05", title: "Завдання до §5" },
        { path: "module06", title: "Завдання до §6" },
        { path: "module07", title: "Завдання до $7" },
        { path: "module08", title: "Завдання до §8" },
        { path: "module09", title: "Завдання до §9" },
        { path: "module10", title: "Завдання до §10" },
        { path: "module11", title: "Завдання до §11" },
        { path: "module12", title: "Завдання до §12" },
        { path: "module13", title: "Завдання до §13" },
        { path: "module14", title: "Завдання до §14" },
        { path: "module15", title: "Завдання до §15" },
      ],
    },
    int: {
      yac: [
        { path: "module01", title: "Завдання до §1" },
        { path: "module02", title: "Завдання до §2" },
        { path: "module03", title: "Завдання до §3" },
        { path: "module04", title: "Завдання до §4" },
        { path: "module05", title: "Завдання до §5" },
        { path: "module06", title: "Завдання до §6" },
        { path: "module07", title: "Завдання до $7" },
        { path: "module08", title: "Завдання до §8" },
        { path: "module09", title: "Завдання до §9" },
        { path: "module10", title: "Завдання до §10" },
        { path: "module11", title: "Завдання до §11" },
        { path: "module12", title: "Завдання до §12" },
        { path: "module13", title: "Завдання до §13" },
        { path: "module14", title: "Завдання до §14" },
        { path: "module15", title: "Завдання до §15" },
        { path: "module16", title: "Завдання до §16" },
        { path: "module17", title: "Завдання до §17" },
        { path: "module18", title: "Завдання до §18" },
        { path: "module19", title: "Завдання до §19" },
        { path: "module20", title: "Завдання до §20" },
        { path: "module21", title: "Завдання до §21" },
        { path: "module22", title: "Завдання до $22" },
        { path: "module23", title: "Завдання до §23" },
        { path: "module24", title: "Завдання до §24" },
        { path: "module25", title: "Завдання до §25" },
        { path: "module26", title: "Завдання до §26" },
        { path: "module27", title: "Завдання до §27" },
        { path: "module28", title: "Завдання до §28" },
        { path: "module29", title: "Завдання до §29" },
      ],
    },
    zbd: {
      pol: [
        { path: "module01", title: "Завдання до §1" },
        { path: "module02", title: "Завдання до §2" },
      ]
    }
  },
  3: {
    ukrmova: {
      avr: [
        { path: "moduleX", title: "Модуль X" },
        { path: "moduleY", title: "Модуль Y" },
      ],
    },
  },
};
