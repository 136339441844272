export const module08 = {
  title: "§ 15. Народні думи.",
  questions: [
    {
      id: 1,
      question: "1. НЕПРАВИЛЬНИМ є твердження, що народні думи –",
      options: [
        { id: "А", text: "ліро-епічні твори" },
        { id: "Б", text: "складаються з трьох частин" },
        { id: "В", text: "виконують у супроводі кобзи або бандури" },
        { id: "Г", text: "поділяються на строфи – куплети й приспів" },
      ],
      correctAnswer: "В",
      isTest: true,
    },
    {
      id: 2,
      question: "2. Народні думи виникли в",
      options: [
        { id: "А", text: "V ст." },
        { id: "Б", text: "ХІІ ст." },
        { id: "В", text: "ХV ст." },
        { id: "Г", text: "ХІХ ст." },
      ],
      correctAnswer: "Г",
      isTest: true,
    },
    {
      id: 3,
      question: "3. У думі «Маруся Богуславка» НЕМАЄ образу",
      options: [
        { id: "А", text: "каменя біленького" },
        { id: "Б", text: "темниці камʼяної" },
        { id: "В", text: "землі християнської" },
        { id: "Г", text: "ладану херувимського" },
      ],
      correctAnswer: "Г",
      isTest: true,
    },
    {
      id: 4,
      question: "4. НЕПРАВИЛЬНИМ є твердження",
      options: [
        {
          id: "А",
          text: "головна героїня врятувала сімсот козаків-невольників",
        },
        { id: "Б", text: "Маруся походить із родини священника" },
        { id: "В", text: "події відбуваються Великодньої суботи" },
        { id: "Г", text: "козаки пробували в неволі три роки" },
      ],
      correctAnswer: "Г",
      isTest: true,
    },
    {
      id: 5,
      question: "5. У думі «Маруся Богуславка» повторюваними є слова",
      options: [
        { id: "А", text: "до темниці приходжає, темницю відмикає" },
        { id: "Б", text: "дівка-бранка, Маруся, попівна Богуславка" },
        { id: "В", text: "на ясні зорі, на тихі води, у край веселий" },
        { id: "Г", text: "білим лицем до сирої землі припадали" },
      ],
      correctAnswer: "Б",
      isTest: true,
    },
    {
      id: 6,
      question:
        "6. Розподіліть поняття, що пов’язані або не пов’язані з думою «Маруся Богуславка».</br><b><i style='text-align: center; display: block;'>Поняття: </i></b>",
      isGame: true,

      initialWords: [
        { id: 1, text: "Азовське море", type: "rightBucket" },
        { id: 2, text: "Чорне море", type: "leftBucket" },
        { id: 3, text: "Туреччина", type: "leftBucket" },
        { id: 4, text: "Крим", type: "rightBucket" },
        { id: 5, text: "ХІІ-ХІІІ ст.", type: "rightBucket" },
        { id: 6, text: "ХVІ-ХVІІ ст.", type: "leftBucket" },
        { id: 7, text: "речитатив", type: "leftBucket" },
        { id: 8, text: "метаморфоза", type: "rightBucket" },
        { id: 9, text: "весняний цикл", type: "rightBucket" },
        { id: 10, text: "заплачка", type: "leftBucket" },
      ],
      leftBucketTitle: "Поняття, повʼязані з думою «Маруся Богуславка»",
      rightBucketTitle: "Поняття, не повʼязані з думою «Маруся Богуславка»",
    },
    {
      id: 7,
      question:
        "7. Переглянувши презентацію «Хто такі кобзарі?» (1 хв 57 с), виконайте завдання: </br><b><i>1. Поділіть презентацію на частини, дайте їм заголовки й запишіть їх у вигляді пунктів плану почутого / побаченого. </br>2. Створіть подібну презентацію на тему «Хто така Маруся Богуславка?»</b></i>",
      videoPath:
        "https://www.youtube.com/embed/g6yar29ksH4?si=KZwpslmi-tbrlvJU",
      addClass: "horizontal",
      isTest: true,
      isCreativeTask: true,
    },

    // Додати нові питання сюди
  ],
};
