export const module02 = {
  title: "Граматична помилка в словосполученні.",
  questions: [
    {
      id: 1,
      question: "1. Граматичну помилку допущено у варіанті",
      options: [
        { id: "А", text: "потребувати в допомозі" },
        { id: "Б", text: "у легкому пальті" },
        { id: "В", text: "за п’ять десята година" },
        { id: "Г", text: "вірний собака" },
      ],
      correctAnswer: "А",
      isTest: true,
    },
    {
      id: 2,
      question: "2. НЕ потребує редагування словосполучення",
      options: [
        { id: "А", text: "поїхати за лікарем" },
        { id: "Б", text: "завідуючий відділу" },
        { id: "В", text: "повідомити мамі" },
        { id: "Г", text: "п’ятеро левиць" },
      ],
      correctAnswer: "В",
      isTest: true,
    },
    {
      id: 3,
      question: "3. Граматично правильно утворено словосполучення",
      options: [
        { id: "А", text: "рятувальник по професії" },
        { id: "Б", text: "твоє день народження" },
        { id: "В", text: "захворіти ковідом" },
        { id: "Г", text: "менший за мене" },
      ],
      correctAnswer: "Г",
      isTest: true,
    },
    {
      id: 4,
      question: "4. Позначте речення, яке потребує редагування:",
      options: [
        { id: "А", text: "Цьогоріч я навчився їзди верхи." },
        { id: "Б", text: "Сходімо в неділю на екскурсію." },
        { id: "В", text: "Це був самий чарівний вечір." },
        { id: "Г", text: "Зустріч призначено о восьмій." },
      ],
      correctAnswer: "В",
      isTest: true,
    },

    {
      id: 5,
      question: "5. Складне словосполучення записано у варіанті",
      options: [
        { id: "А", text: "немовбито чарівна паличка" },
        { id: "Б", text: "пролягли білосніжні тумани" },
        { id: "В", text: "не потрапляти в такт музики" },
        { id: "Г", text: "за сприяння департаменту" },
      ],
      correctAnswer: "В",
      isTest: true,
    },
    {
      id: 6,
      question: "6. Прислівникове словосполучення записано у варіанті",
      options: [
        { id: "А", text: "надзвичайно впевнено" },
        { id: "Б", text: "надзвичайно сміливий" },
        { id: "В", text: "несподівано зʼявитися" },
        { id: "Г", text: "зʼявитися вчасно" },
      ],
      correctAnswer: "А",
      isTest: true,
    },
    {
      id: 7,
      question:
        "7. Розподіліть конструкції у дві чарунки – словосполучення і сполуки слів:",
      isGame: true,
      initialWords: [
        { id: 1, text: "буду опановувати", type: "rightBucket" },
        { id: 2, text: "вареники з вишнями", type: "leftBucket" },
        { id: 3, text: "те завдання", type: "leftBucket" },
        { id: 4, text: "обібрати до нитки", type: "rightBucket" },
        { id: 5, text: "найвищий з-поміж соняшників", type: "leftBucket" },
        { id: 6, text: "оригінальне есе", type: "leftBucket" },
        { id: 7, text: "прийшли батьки", type: "rightBucket" },
        { id: 8, text: "навколо гімназії", type: "rightBucket" },
        { id: 9, text: "радісно від успіху", type: "leftBucket" },
        { id: 10, text: "менш відкритий", type: "rightBucket" },
        { id: 11, text: "хлопці і дівчата", type: "rightBucket" },
        { id: 12, text: "збігаючи з гори", type: "leftBucket" },
      ],
      leftBucketTitle: "Словосполучення",
      rightBucketTitle: "Сполуки слів",
    },
    // Додати нові питання сюди
  ],
};
