export const module10 = {
  title: "Біблійні жанри та мотиви в літературі. Антуан де Сент-Екзюпері «Маленький принц»",
  questions: [
    {
      id: 1,
      question: "1. Творчість Антуана де Сент-Екзюпері належить до національних надбань",
      options: [
        { id: "А", text: "Англії" },
        { id: "Б", text: "Франції" },
        { id: "В", text: "Італії" },
        { id: "Г", text: "Греції" },
      ],
      correctAnswer: "Б",
      isTest: true,
    },
    {
      id: 2,
      question: "2. Який біблійний жанр покладено в основу «Маленького принца»?",
      options: [
        { id: "А", text: "псалом" },
        { id: "Б", text: "притча" },
        { id: "В", text: "хроніка" },
        { id: "Г", text: "послання" },
      ],
      correctAnswer: "Б",
      isTest: true,
    },
    {
      id: 3,
      question: "3. Продовжте правило Маленького принца: «Причепурився сам уранці, причепури гарненько і...»",
      options: [
        { id: "А", text: "своє ліжко" },
        { id: "Б", text: "свою кімнату" },
        { id: "В", text: "свій двір" },
        { id: "Г", text: "свою планету" },
      ],
      correctAnswer: "Г",
      isTest: true,
    },
    {
      id: 4,
      question: "4. Яке слово пропущене в таємниці лиса, яку він відкрив Маленькому принцу: «Добре бачить лише …. . Найголовнішого очима не побачиш»?",
      options: [
        { id: "А", text: "телескоп" },
        { id: "Б", text: "мікроскоп" },
        { id: "В", text: "серце" },
        { id: "Г", text: "душа" },
      ],
      correctAnswer: "В",
      isTest: true,
    },
    {
      id: 5,
      question: "5. Що Маленький принц вважав найкрасивішим на світі?",
      options: [
        { id: "А", text: "зірки" },
        { id: "Б", text: "квіти" },
        { id: "В", text: "діаманти" },
        { id: "Г", text: "золото" },
      ],
      correctAnswer: "А",
      isTest: true,
    },
    {
      id: 6,
      question: "6. На думку Маленького принца, знають, чого шукають, тільки:",
      options: [
        { id: "А", text: "археологи" },
        { id: "Б", text: "учені" },
        { id: "В", text: "дорослі" },
        { id: "Г", text: "діти" },
      ],
      correctAnswer: "Г",
      isTest: true,
    },
    // Додайте нові питання тут
  ],
};
