
export const module02 = {
  title: "Додаток",
  questions: [
    {
      id: 1,
      question: "1. Яка кількість додатків у поданому реченні? Наш рідний край чарує усіх своєю культурою та чудовими краєвидами.",
      options: [
        { id: "А", text: "1" },
        { id: "Б", text: "2" },
        { id: "В", text: "3" },
        { id: "Г", text: "4" },
      ],
      correctAnswer: "В",
      isTest: true,
    },
    {
      id: 2,
      question: "2. Вкажіть додаток у реченні: Україна славиться своїми талановитими спортсменами.",
      options: [
        { id: "А", text: "Україна" },
        { id: "Б", text: "своїми" },
        { id: "В", text: "спортсменами" },
        { id: "Г", text: "славиться" },
        { id: "Д", text: "талановитими" },
      ],
      correctAnswer: "В",
      isTest: true,
    },
    {
      id: 3,
      question: "3. Вкажіть речення, у якому є додаток:",
      options: [
        { id: "А", text: "Київ - столиця України." },
        { id: "Б", text: "Ми пишаємося культурною спадщиною нашої держави." },
      ],
      correctAnswer: "Б",
      isTest: true,
    },
    {
      id: 4,
      question: "4. Вкажіть додатки у реченні: Без батьківщини немає людини.",
      options: [
        { id: "А", text: "Без" },
        { id: "Б", text: "батьківщини" },
        { id: "В", text: "немає" },
      ],
      correctAnswer: "Б",
      isTest: true,
    },

    // Додайте нові питання тут
  ],
};
