import React, { Component } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Main from "./components/main-page/Main.js";
import { getUserName } from "./controller/UserController.js";
import "./App.css";
import "./css/responsive.css";
import ModulePage from "./components/modules/ModulePage.js";

import Module from "./components/modules/module/Module.js";
import TheoryPage from "./components/theory/TheoryPage.js";

import { module01 } from "./classes/class8/subjects/ukrmova/Avramenko/modules/module01.js";
import { module02 } from "./classes/class8/subjects/ukrmova/Avramenko/modules/module02.js";
import { module03 } from "./classes/class8/subjects/ukrmova/Avramenko/modules/module03.js";
import { module2 } from "./classes/class8/subjects/ukrmova/Avramenko/modules/module2.js";
import { module3 } from "./classes/class8/subjects/ukrmova/Avramenko/modules/module3.js";
import { module4 } from "./classes/class8/subjects/ukrmova/Avramenko/modules/module4.js";
import { module5 } from "./classes/class8/subjects/ukrmova/Avramenko/modules/module5.js";
import { module6 } from "./classes/class8/subjects/ukrmova/Avramenko/modules/module6.js";
import { module7 } from "./classes/class8/subjects/ukrmova/Avramenko/modules/module7.js";
import { module8 } from "./classes/class8/subjects/ukrmova/Avramenko/modules/module8.js";
import { module9 } from "./classes/class8/subjects/ukrmova/Avramenko/modules/module9.js";
import { module23 } from "./classes/class8/subjects/ukrmova/Avramenko/modules/module23.js";
import { module18_19 } from "./classes/class8/subjects/ukrmova/Avramenko/modules/module18_19.js";
import { module20_21 } from "./classes/class8/subjects/ukrmova/Avramenko/modules/module20_21.js";

import { module01 as ukrlitModule01 } from "./classes/class8/subjects/ukrlit/Avramenko/modules/module01.js";
import { module02 as ukrlitModule02 } from "./classes/class8/subjects/ukrlit/Avramenko/modules/module02.js";
import { module03 as ukrlitModule03 } from "./classes/class8/subjects/ukrlit/Avramenko/modules/module03.js";
import { module04 as ukrlitModule04 } from "./classes/class8/subjects/ukrlit/Avramenko/modules/module04.js";
import { module05 as ukrlitModule05 } from "./classes/class8/subjects/ukrlit/Avramenko/modules/module05.js";
import { module06 as ukrlitModule06 } from "./classes/class8/subjects/ukrlit/Avramenko/modules/module06.js";
import { module07 as ukrlitModule07 } from "./classes/class8/subjects/ukrlit/Avramenko/modules/module07.js";
import { module08 as ukrlitModule08 } from "./classes/class8/subjects/ukrlit/Avramenko/modules/module08.js";
import { module09 as ukrlitModule09 } from "./classes/class8/subjects/ukrlit/Avramenko/modules/module09.js";
import { module10 as ukrlitModule10 } from "./classes/class8/subjects/ukrlit/Avramenko/modules/module10.js";
import { module11 as ukrlitModule11 } from "./classes/class8/subjects/ukrlit/Avramenko/modules/module11.js";

import { module01 as ukrlitModule01Yac } from "./classes/class8/subjects/ukrlit/Yacenko/modules/module01.js";
import { module02 as ukrlitModule02Yac } from "./classes/class8/subjects/ukrlit/Yacenko/modules/module02.js";
import { module03 as ukrlitModule03Yac } from "./classes/class8/subjects/ukrlit/Yacenko/modules/module03.js";
import { module04 as ukrlitModule04Yac } from "./classes/class8/subjects/ukrlit/Yacenko/modules/module04.js";
import { module05 as ukrlitModule05Yac } from "./classes/class8/subjects/ukrlit/Yacenko/modules/module05.js";
import { module06 as ukrlitModule06Yac } from "./classes/class8/subjects/ukrlit/Yacenko/modules/module06.js";
import { module07 as ukrlitModule07Yac } from "./classes/class8/subjects/ukrlit/Yacenko/modules/module07.js";
import { module08 as ukrlitModule08Yac } from "./classes/class8/subjects/ukrlit/Yacenko/modules/module08.js";
import { module09 as ukrlitModule09Yac } from "./classes/class8/subjects/ukrlit/Yacenko/modules/module09.js";
import { module10 as ukrlitModule10Yac } from "./classes/class8/subjects/ukrlit/Yacenko/modules/module10.js";
import { module11 as ukrlitModule11Yac } from "./classes/class8/subjects/ukrlit/Yacenko/modules/module11.js";
import { module12 as ukrlitModule12Yac } from "./classes/class8/subjects/ukrlit/Yacenko/modules/module12.js";
import { module13 as ukrlitModule13Yac } from "./classes/class8/subjects/ukrlit/Yacenko/modules/module13.js";
import { module14 as ukrlitModule14Yac } from "./classes/class8/subjects/ukrlit/Yacenko/modules/module14.js";
import { module15 as ukrlitModule15Yac } from "./classes/class8/subjects/ukrlit/Yacenko/modules/module15.js";

import { module01 as ukrlitModule1IntYac } from "./classes/class8/subjects/int/Yacenko/modules/module01.js";
import { module02 as ukrlitModule2IntYac } from "./classes/class8/subjects/int/Yacenko/modules/module02.js";
import { module03 as ukrlitModule3IntYac } from "./classes/class8/subjects/int/Yacenko/modules/module03.js";
import { module04 as ukrlitModule4IntYac } from "./classes/class8/subjects/int/Yacenko/modules/module04.js";
import { module05 as ukrlitModule5IntYac } from "./classes/class8/subjects/int/Yacenko/modules/module05.js";
import { module06 as ukrlitModule6IntYac } from "./classes/class8/subjects/int/Yacenko/modules/module06.js";
import { module07 as ukrlitModule7IntYac } from "./classes/class8/subjects/int/Yacenko/modules/module07.js";
import { module08 as ukrlitModule8IntYac } from "./classes/class8/subjects/int/Yacenko/modules/module08.js";
import { module09 as ukrlitModule9IntYac } from "./classes/class8/subjects/int/Yacenko/modules/module09.js";
import { module10 as ukrlitModule10IntYac } from "./classes/class8/subjects/int/Yacenko/modules/module10.js";
import { module11 as ukrlitModule11IntYac } from "./classes/class8/subjects/int/Yacenko/modules/module11.js";
import { module12 as ukrlitModule12IntYac } from "./classes/class8/subjects/int/Yacenko/modules/module12.js";
import { module13 as ukrlitModule13IntYac } from "./classes/class8/subjects/int/Yacenko/modules/module13.js";
import { module14 as ukrlitModule14IntYac } from "./classes/class8/subjects/int/Yacenko/modules/module14.js";
import { module15 as ukrlitModule15IntYac } from "./classes/class8/subjects/int/Yacenko/modules/module15.js";
import { module16 as ukrlitModule16IntYac } from "./classes/class8/subjects/int/Yacenko/modules/module16.js";
import { module17 as ukrlitModule17IntYac } from "./classes/class8/subjects/int/Yacenko/modules/module17.js";
import { module18 as ukrlitModule18IntYac } from "./classes/class8/subjects/int/Yacenko/modules/module18.js";
import { module19 as ukrlitModule19IntYac } from "./classes/class8/subjects/int/Yacenko/modules/module19.js";
import { module20 as ukrlitModule20IntYac } from "./classes/class8/subjects/int/Yacenko/modules/module20.js";
import { module21 as ukrlitModule21IntYac } from "./classes/class8/subjects/int/Yacenko/modules/module21.js";
import { module22 as ukrlitModule22IntYac } from "./classes/class8/subjects/int/Yacenko/modules/module22.js";
import { module23 as ukrlitModule23IntYac } from "./classes/class8/subjects/int/Yacenko/modules/module23.js";
import { module24 as ukrlitModule24IntYac } from "./classes/class8/subjects/int/Yacenko/modules/module24.js";
import { module25 as ukrlitModule25IntYac } from "./classes/class8/subjects/int/Yacenko/modules/module25.js";
import { module26 as ukrlitModule26IntYac } from "./classes/class8/subjects/int/Yacenko/modules/module26.js";
import { module27 as ukrlitModule27IntYac } from "./classes/class8/subjects/int/Yacenko/modules/module27.js";
import { module28 as ukrlitModule28IntYac } from "./classes/class8/subjects/int/Yacenko/modules/module28.js";
import { module29 as ukrlitModule29IntYac } from "./classes/class8/subjects/int/Yacenko/modules/module29.js";

import { module01 as zbdModule1Pol } from "./classes/class8/subjects/zbd/Polishuk/modules/module01.js";
import { module02 as zbdModule2Pol } from "./classes/class8/subjects/zbd/Polishuk/modules/module02.js";

import { module01 as ukrmovaModule1Gol } from "./classes/class8/subjects/ukrmova/Golub/modules/module01.js";
import { module02 as ukrmovaModule2Gol } from "./classes/class8/subjects/ukrmova/Golub/modules/module02.js";
import { module03 as ukrmovaModule3Gol } from "./classes/class8/subjects/ukrmova/Golub/modules/module03.js";
import { module04 as ukrmovaModule4Gol } from "./classes/class8/subjects/ukrmova/Golub/modules/module04.js";
import { module05 as ukrmovaModule5Gol } from "./classes/class8/subjects/ukrmova/Golub/modules/module05.js";


import { test_image } from "./classes/class8/subjects/ukrmova/Avramenko/modules/test_image.js";

import img1_1 from "./images/1_1.jpg";
import ClassPage from "./components/class_page/class_page.js";
import SubjectPage from "./components/subject-page/subject_page.js";
import AuthorPage from "./components/author_page/AuhtorPage.js";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userName: null,
      loading: true,
    };
  }

  componentDidMount() {
    this.loadUserName();
  }

  loadUserName = async () => {
    try {
      const userNameResponse = await getUserName();
      if (userNameResponse && userNameResponse.name) {
        this.setState({ userName: userNameResponse.name, loading: false });
      } else {
        this.setState({ loading: false });
      }
    } catch (error) {
      console.error("Error fetching user info:", error);
      this.setState({ loading: false });
    }
  };

  render() {
    const { userName, loading } = this.state;

    if (loading) {
      // return <Loader />;
    }

    //TODO
    if (!userName) {
      // window.location.href = "http://localhost/api/login";
      // window.location.href = "http://hramota.fun/api/login";
    }

    return (
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Main userName={userName} />} />
          <Route
            path="/class/8/ukrmova/avr/modules/module01"
            element={<ModulePage component={Module} module={module01} />}
          />

          <Route
            path="/class/8/ukrmova/avr/modules/module02"
            element={<ModulePage component={Module} module={module02} />}
          />

          <Route
            path="/class/8/ukrmova/avr/modules/module03"
            element={<ModulePage component={Module} module={module03} />}
          />

          <Route
            path="/class/8/ukrmova/avr/modules/module2"
            element={<ModulePage component={Module} module={module2} />}
          />

          <Route
            path="/class/8/ukrmova/avr/modules/module3"
            element={<ModulePage component={Module} module={module3} />}
          />

          <Route
            path="/class/8/ukrmova/avr/modules/module4"
            element={<ModulePage component={Module} module={module4} />}
          />

          <Route
            path="/class/8/ukrmova/avr/modules/module5"
            element={<ModulePage component={Module} module={module5} />}
          />

          <Route
            path="/class/8/ukrmova/avr/modules/module6"
            element={<ModulePage component={Module} module={module6} />}
          />

          <Route
            path="/class/8/ukrmova/avr/modules/module7"
            element={<ModulePage component={Module} module={module7} />}
          />

          <Route
            path="/class/8/ukrmova/avr/modules/module8"
            element={<ModulePage component={Module} module={module8} />}
          />

          <Route
            path="/class/8/ukrmova/avr/modules/module9"
            element={<ModulePage component={Module} module={module9} />}
          />

          <Route
            path="/class/8/ukrmova/avr/modules/module18_19"
            element={<ModulePage component={Module} module={module18_19} />}
          />

          <Route
            path="/class/8/ukrmova/avr/modules/module20_21"
            element={<ModulePage component={Module} module={module20_21} />}
          />

          <Route
            path="/class/8/ukrmova/avr/modules/module23"
            element={<ModulePage component={Module} module={module23} />}
          />

          <Route
            path="/class/8/ukrmova/avr/modules/test"
            element={<ModulePage component={Module} module={test_image} />}
          />

          <Route
            path="/class/8/ukrlit/avr/modules/module01"
            element={<ModulePage component={Module} module={ukrlitModule01} />}
          />

          <Route
            path="/class/8/ukrlit/avr/modules/module02"
            element={<ModulePage component={Module} module={ukrlitModule02} />}
          />

          <Route
            path="/class/8/ukrlit/avr/modules/module03"
            element={<ModulePage component={Module} module={ukrlitModule03} />}
          />

          <Route
            path="/class/8/ukrlit/avr/modules/module04"
            element={<ModulePage component={Module} module={ukrlitModule04} />}
          />

          <Route
            path="/class/8/ukrlit/avr/modules/module05"
            element={<ModulePage component={Module} module={ukrlitModule05} />}
          />

          <Route
            path="/class/8/ukrlit/avr/modules/module06"
            element={<ModulePage component={Module} module={ukrlitModule06} />}
          />

          <Route
            path="/class/8/ukrlit/avr/modules/module07"
            element={<ModulePage component={Module} module={ukrlitModule07} />}
          />

          <Route
            path="/class/8/ukrlit/avr/modules/module08"
            element={<ModulePage component={Module} module={ukrlitModule08} />}
          />

          <Route
            path="/class/8/ukrlit/avr/modules/module09"
            element={<ModulePage component={Module} module={ukrlitModule09} />}
          />

          <Route
            path="/class/8/ukrlit/avr/modules/module10"
            element={<ModulePage component={Module} module={ukrlitModule10} />}
          />

          <Route
            path="/class/8/ukrlit/avr/modules/module11"
            element={<ModulePage component={Module} module={ukrlitModule11} />}
          />

<Route
            path="/class/8/ukrlit/yac/modules/module01"
            element={<ModulePage component={Module} module={ukrlitModule01Yac} />}
          />
    
<Route
            path="/class/8/ukrlit/yac/modules/module02"
            element={<ModulePage component={Module} module={ukrlitModule02Yac} />}
          />


<Route
            path="/class/8/ukrlit/yac/modules/module03"
            element={<ModulePage component={Module} module={ukrlitModule03Yac} />}
          />
<Route
            path="/class/8/ukrlit/yac/modules/module04"
            element={<ModulePage component={Module} module={ukrlitModule04Yac} />}
          />
<Route
            path="/class/8/ukrlit/yac/modules/module05"
            element={<ModulePage component={Module} module={ukrlitModule05Yac} />}
          />

<Route
            path="/class/8/ukrlit/yac/modules/module06"
            element={<ModulePage component={Module} module={ukrlitModule06Yac} />}
          />
 <Route
            path="/class/8/ukrlit/yac/modules/module07"
            element={<ModulePage component={Module} module={ukrlitModule07Yac} />}
          />

<Route
            path="/class/8/ukrlit/yac/modules/module08"
            element={<ModulePage component={Module} module={ukrlitModule08Yac} />}
          />
    
<Route
            path="/class/8/ukrlit/yac/modules/module09"
            element={<ModulePage component={Module} module={ukrlitModule09Yac} />}
          />


<Route
            path="/class/8/ukrlit/yac/modules/module10"
            element={<ModulePage component={Module} module={ukrlitModule10Yac} />}
          />
<Route
            path="/class/8/ukrlit/yac/modules/module11"
            element={<ModulePage component={Module} module={ukrlitModule11Yac} />}
          />
<Route
            path="/class/8/ukrlit/yac/modules/module12"
            element={<ModulePage component={Module} module={ukrlitModule12Yac} />}
          />

<Route
            path="/class/8/ukrlit/yac/modules/module13"
            element={<ModulePage component={Module} module={ukrlitModule13Yac} />}
          />
 <Route
            path="/class/8/ukrlit/yac/modules/module14"
            element={<ModulePage component={Module} module={ukrlitModule14Yac} />}
            
          />
<Route
            path="/class/8/ukrlit/yac/modules/module15"
            element={<ModulePage component={Module} module={ukrlitModule15Yac} />}
          />

<Route
            path="/class/8/int/yac/modules/module01"
            element={<ModulePage component={Module} module={ukrlitModule1IntYac} />}
          />
          <Route
            path="/class/8/int/yac/modules/module02"
            element={<ModulePage component={Module} module={ukrlitModule2IntYac} />}
          />

<Route
            path="/class/8/int/yac/modules/module03"
            element={<ModulePage component={Module} module={ukrlitModule3IntYac} />}
          />
<Route
            path="/class/8/int/yac/modules/module04"
            element={<ModulePage component={Module} module={ukrlitModule4IntYac} />}
          />
<Route
            path="/class/8/int/yac/modules/module05"
            element={<ModulePage component={Module} module={ukrlitModule5IntYac} />}
          />
<Route
            path="/class/8/int/yac/modules/module06"
            element={<ModulePage component={Module} module={ukrlitModule6IntYac} />}
          />
 <Route
            path="/class/8/int/yac/modules/module07"
            element={<ModulePage component={Module} module={ukrlitModule7IntYac} />}
          />   
<Route
            path="/class/8/int/yac/modules/module08"
            element={<ModulePage component={Module} module={ukrlitModule8IntYac} />}
          />
<Route
            path="/class/8/int/yac/modules/module09"
            element={<ModulePage component={Module} module={ukrlitModule9IntYac} />}
          />
<Route
            path="/class/8/int/yac/modules/module10"
            element={<ModulePage component={Module} module={ukrlitModule10IntYac} />}
          />
<Route
            path="/class/8/int/yac/modules/module11"
            element={<ModulePage component={Module} module={ukrlitModule11IntYac} />}
          />
<Route
            path="/class/8/int/yac/modules/module12"
            element={<ModulePage component={Module} module={ukrlitModule12IntYac} />}
          />
<Route
            path="/class/8/int/yac/modules/module13"
            element={<ModulePage component={Module} module={ukrlitModule13IntYac} />}
          />
<Route
            path="/class/8/int/yac/modules/module14"
            element={<ModulePage component={Module} module={ukrlitModule14IntYac} />}
          />
<Route
            path="/class/8/int/yac/modules/module15"
            element={<ModulePage component={Module} module={ukrlitModule15IntYac} />}
          />
<Route
            path="/class/8/int/yac/modules/module16"
            element={<ModulePage component={Module} module={ukrlitModule16IntYac} />}
          />
<Route
            path="/class/8/int/yac/modules/module17"
            element={<ModulePage component={Module} module={ukrlitModule17IntYac} />}
          />
<Route
            path="/class/8/int/yac/modules/module18"
            element={<ModulePage component={Module} module={ukrlitModule18IntYac} />}
          />
<Route
            path="/class/8/int/yac/modules/module19"
            element={<ModulePage component={Module} module={ukrlitModule19IntYac} />}
          />
<Route
            path="/class/8/int/yac/modules/module20"
            element={<ModulePage component={Module} module={ukrlitModule20IntYac} />}
          />
<Route
            path="/class/8/int/yac/modules/module21"
            element={<ModulePage component={Module} module={ukrlitModule21IntYac} />}
          />
<Route
            path="/class/8/int/yac/modules/module22"
            element={<ModulePage component={Module} module={ukrlitModule22IntYac} />}
          />
<Route
            path="/class/8/int/yac/modules/module23"
            element={<ModulePage component={Module} module={ukrlitModule23IntYac} />}
          />
<Route
            path="/class/8/int/yac/modules/module24"
            element={<ModulePage component={Module} module={ukrlitModule24IntYac} />}
          />
<Route
            path="/class/8/int/yac/modules/module25"
            element={<ModulePage component={Module} module={ukrlitModule25IntYac} />}
          />
<Route
            path="/class/8/int/yac/modules/module26"
            element={<ModulePage component={Module} module={ukrlitModule26IntYac} />}
          />
<Route
            path="/class/8/int/yac/modules/module27"
            element={<ModulePage component={Module} module={ukrlitModule27IntYac} />}
          />
<Route
            path="/class/8/int/yac/modules/module28"
            element={<ModulePage component={Module} module={ukrlitModule28IntYac} />}
          />
<Route
            path="/class/8/int/yac/modules/module29"
            element={<ModulePage component={Module} module={ukrlitModule29IntYac} />}
          />
<Route
            path="/class/8/zbd/pol/modules/module01"
            element={<ModulePage component={Module} module={zbdModule1Pol} />}
          />
<Route
            path="/class/8/zbd/pol/modules/module02"
            element={<ModulePage component={Module} module={zbdModule2Pol} />}
          />

<Route
            path="/class/8/ukrmova/gol/modules/module01"
            element={<ModulePage component={Module} module={ukrmovaModule1Gol} />}
          />

          
<Route
            path="/class/8/ukrmova/gol/modules/module02"
            element={<ModulePage component={Module} module={ukrmovaModule2Gol} />}
          />
<Route
            path="/class/8/ukrmova/gol/modules/module03"
            element={<ModulePage component={Module} module={ukrmovaModule3Gol} />}
          />
<Route
            path="/class/8/ukrmova/gol/modules/module04"
            element={<ModulePage component={Module} module={ukrmovaModule4Gol} />}
          />
          
          
          <Route
          path="/class/8/ukrmova/gol/modules/module05"
          element={<ModulePage component={Module} module={ukrmovaModule5Gol} />}
        />




          <Route
            path="/theory/module1"
            element={
              <TheoryPage
                content={
                  <img style={{ width: "70%" }} src={img1_1} alt="img1_1" />
                }
              />
            }
          />

          <Route
            path="/theory/module2"
            element={
              <TheoryPage
                content={
                  <iframe
                    width="560"
                    height="315"
                    src={`https://www.youtube.com/embed/B9tXngTv8ds?si=KMO7CB-Ey1fXi33r`}
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  ></iframe>
                }
              />
            }
          />

          <Route path="/class/:id" element={<ClassPage />} />

          <Route path="/class/:id/:subject" element={<SubjectPage />} />

          <Route
            path="/class/:id/:subject/:author/modules"
            element={<AuthorPage />}
          />
        </Routes>
      </BrowserRouter>
    );
  }
}

export default App;
