export const module27 = {
  title: "«Чи не той то хміль» - народна пісня про перемогу козацького війська у битві під Жовтими Водами",
  questions: [
        {
      id: 4,
      question: "1. Пісня «Чи не той то хміль...» уславлює",
      options: [
        { id: "А", text: "перемогу запорожців над татарами" },
        { id: "Б", text: "остаточну перемогу козаків у Визвольній війні" },
        { id: "В", text: "першу перемогу у Визвольній війні та Богдана Хмельницького" },
        { id: "Г", text: "Богдана Хмельницького" },
      ],
      subtitle: "Тестові питання",
      correctAnswer: "В",
      isTest: true,
    },
    {
      id: 5,
      question: "2. Із хмелем народ порівнює Богдана Хмельницького за його",
      options: [
        { id: "А", text: "відвагу" },
        { id: "Б", text: "енергійну й вольову вдачу" },
        { id: "В", text: "патріотизм" },
        { id: "Г", text: "сміливість" },
      ],
      correctAnswer: "Б",
      isTest: true,
    },
    {
      id: 6,
      question: "3. У пісні «Чи не той то хміль» НЕ відображені почуття",
      options: [
        { id: "А", text: "гордості за гідного гетьмана" },
        { id: "Б", text: "любові до рідного краю" },
        { id: "В", text: "захоплення козацькою звитягою" },
        { id: "Г", text: "дошкульної іронії щодо загарбників" },
      ],
      correctAnswer: "Б",
      isTest: true,
    },
    {
      id: 7,
      question: "4. Який художній засіб використано у поданих рядках “Чи не той то хміль, що коло тичин в’ється? /Гей, той то Хмельницький, що з ляхами б’ється”?",
      options: [
        { id: "А", text: "постійні епітети" },
        { id: "Б", text: "пестливі слова" },
        { id: "В", text: "паралелізм" },
        { id: "Г", text: "метафору" },
      ],
      correctAnswer: "В",
      isTest: true,
    },
    {
      id: 8,
      question: "5. У яких видах мистецтва НЕ відображений образ Богдана Хмельницького?",
      options: [
        { id: "А", text: "анімаційних фільмах" },
        { id: "Б", text: "образотворчому мистецтві" },
        { id: "В", text: "скульптурі" },
        { id: "Г", text: "літературі" },
      ],
      correctAnswer: "А",
      isTest: true,
    },
    {
      id: 9,
      question: "6. У якому місті споруджено першу скульптурну композицію на честь Богдана Хмельницького?",
      options: [
        { id: "А", text: "Жовті Води" },
        { id: "Б", text: "Дніпро" },
        { id: "В", text: "Київ" },
        { id: "Г", text: "Чигирин" },
      ],
      correctAnswer: "В",
      isTest: true,
    },
    // Додати нові питання сюди
  ],
};
