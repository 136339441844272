
export const module01 = {
title: "Відомості про синтаксис. Різноманітний світ природи.", 
questions: [
  {
    id: 1,
    question:
      "1. З’ясуйте частину мови виділених слів",
    dropdownQuestionParts: [
      "Любов ",
      { id: "dropdown1",},
      " до ",
      { id: "dropdown2",},
      " Батьківщини починається ",
      { id: "dropdown3",},
      " з любові до рідної ",
      { id: "dropdown4",},
      " природи.",
    ],
    options: [
      { id: "А", text: "Іменник" },
      { id: "Б", text: "Прийменник," },
      { id: "В", text: "Дієслово" },
      { id: "Г", text: "Прикметник" },
      { id: "Д", text: "Сполучник" },
    ],
    correctAnswers: {
      dropdown1: "А",
      dropdown2: "Б",
      dropdown3: "В",
      dropdown4: "Г",
    },
    showAnswerOptions: true,
    dropdownAmount: 4,
    isTest: false,
  },
    

    // Додати нові питання сюди
  ],
};