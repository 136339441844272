

import {text_mod_1, text_mod_2, text_mod_3} from "../text/Text.js";

export const module01 = {
title: "Художній твір в історико-культурному контексті доби", 
questions: [
 {
      id: 1,
      subtitle: "ДІЗНАЙСЯ БІЛЬШЕ",
      onlyText: true,
      text: text_mod_1, },

      {
        id: 2,
        subtitle: "ДОСЛІДЖУЄМО МЕДІА",
        onlyText: true,
        text: text_mod_2, },

        {
          id: 3,
          subtitle: "ЧИТАЦЬКЕ ДОЗВІЛЛЯ",
          onlyText: true,
          text: text_mod_3, },

 {
      id: 4,
      question: "1.	Найзагальніші традиції, погляди, уподобання певної доби, якими вона відрізняється від інших епох, становлять",
      options: [
        { id: "А", text: "літературу певного періоду" },
        { id: "Б", text: "стиль доби" },
        { id: "В", text: "мистецтво" },
        { id: "Г", text: "фольклор" },
       
      ],
      subtitle: "Тестові питання",
      correctAnswer: "Б",
      isTest: true,
    },
    {
      id: 5,
      question: "2.	Стиль певної доби найвиразніше проявляється у",
      options: [
        { id: "А", text: "філософії" },
        { id: "Б", text: "мистецтві" },
        { id: "В", text: "науці" },
        { id: "Г", text: "побуті" },
      ],
      
      correctAnswer: "Б",
      isTest: true,
    },
    {
      id: 6,
      question: "3.	Об’єднання митців які мають споріднені риси стилів і творять у межах спільної доби та нації утворює",
      options: [
        { id: "А", text: "мистецький напрям" },
        { id: "Б", text: "авторський стиль" },
        { id: "В", text: "мистецьку течію" },
        { id: "Г", text: "мистецькі паралелі" },
  
      ],
      correctAnswer: "А",
      isTest: true,
    },
    {
      id: 7,
      question: "4.	Об’єднання митців на підставі споріднених творчих принципів утворює",
      options: [
        { id: "А", text: "мистецький напрям" },
        { id: "Б", text: "авторський стиль" },
        { id: "В", text: "мистецьку течію" },
        { id: "Г", text: "мистецькі паралелі" },
      ],
      correctAnswer: "В",
      isTest: true,
    },
    {
      id: 8,
      question: "5.	Авторський стиль відіграє головну роль у визначенні",
      options: [
        { id: "А", text: "популярності твору" },
        { id: "Б", text: "художньої своєрідності твору" },
        { id: "В", text: "теми твору" },
        { id: "Г", text: "проблематики твору" },
      
      ],
      correctAnswer: "Б",
      isTest: true,
    },
    {
      id: 9,
      question: "6.	Під час утвердження якого мистецького напряму ми живемо?",
      options: [
        { id: "А", text: "модернізму" },
        { id: "Б", text: "постмодернізму" },
        { id: "В", text: "метамодернізму" },
        { id: "Г", text: "реалізму" },
      
      ],
      correctAnswer: "Б",
      isTest: true,
    },

    // Додати нові питання сюди
  ],
};