export const getUserName = async () => {
  const SERVER_URL_PRODUCTION = "https://e-gramota.com.ua";

  const SERVER_URL_DEV = "https://localhost";

  const prod = false;

  let BASE_URL = null;
  if (prod) {
    BASE_URL = SERVER_URL_PRODUCTION;
  } else {
    BASE_URL = SERVER_URL_DEV;
  }

  try {
    const response = await fetch(BASE_URL + "/api/me", {
      method: "GET",
      redirect: "follow",
      credentials: "include",
    }).then((response) => response);

    // //TODO: remove for prod
    // if (response.redirected) {
    //   // document.location = response.url;
    //   // return;
    // }

    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      // console.error("Error fetching user info:", response.statusText);
      return null;
    }
  } catch (error) {
    // console.error("Error fetching user info:", error);
    return null;
  }
};
