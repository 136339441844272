import Offcanvas from "react-bootstrap/Offcanvas";

import logoG from "../../../images/logoG.jpg";

import "./LeftMobileMenu.css";
import "./responsive.css";

import LessonDropdown from "./LessonDropdown/LessonDropdown";

function LeftMobileMenu({ show, handleClose, backdropClassName = "gegar" }) {
  return (
    <>
      <Offcanvas
        show={show}
        onHide={handleClose}
        backdropClassName={backdropClassName}
      >
        <Offcanvas.Header closeButton>
          {/* <Offcanvas.Title>Offcanvas</Offcanvas.Title> */}
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="mobile-logoH">
            <img alt="logoH" src={logoG} />
          </div>

          <div className="lesson-drop">
            <LessonDropdown />
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

export default LeftMobileMenu;
