export const module3 = {
  title: "§ 3. Дієслово та його форми",
  questions: [
    {
      id: 1,
      question: "1. Дієсловами є всі слова у варіанті",
      options: [
        { id: "А", text: "зустріч, зустрічний, зустрічати" },
        { id: "Б", text: "фарба, пофарбований, фарбувати" },
        { id: "В", text: "купівля, купуючи, розкуплено" },
        { id: "Г", text: "купити, куплений, відкупившись" },
      ],
      correctAnswer: "Г",
      isTest: true,
    },
    {
      id: 2,
      question: "2. Лише змінні форми дієслова записано у варіанті",
      options: [
        { id: "А", text: "позеленілий, читатиму, примчавши" },
        { id: "Б", text: "зібралися, підкорений, проголошена" },
        { id: "В", text: "скроєний, відчиняймо, виконано" },
        { id: "Г", text: "літаймо, спійманий, відбуватися" },
      ],
      correctAnswer: "Б",
      isTest: true,
    },
    {
      id: 3,
      question: "3. Лише незмінні форми дієслова записано у варіанті",
      options: [
        { id: "А", text: "розквітлий, наїжачився, пам’ятаючи" },
        { id: "Б", text: "напудрена, проспівали, звито" },
        { id: "В", text: "усвідомлюю, намальована, берегти" },
        { id: "Г", text: "створити, засміявшись, закосичено" },
      ],
      correctAnswer: "Г",
      isTest: true,
    },
    {
      id: 4,
      question:
        "4. Форму наказового способу дієслова утворено <i>НЕПРАВИЛЬНО</i>",
      options: [
        {
          id: "А",
          text: "давай співати",
        },
        { id: "Б", text: "хай танцюють" },
        { id: "В", text: "підфарбуймося" },
        { id: "Г", text: "нехай прийде" },
      ],
      correctAnswer: "А",
      isTest: true,
    },
    {
      id: 5,
      question:
        "5. З’ясуйте форму виділених дієслів <i>(клітинка позначає попереднє слово).</i>",
      dropdownQuestionParts: [
        { id: "dropdown1", text: "Переправивши " },
        " своє велетенське  <br />військо на лівий берег Бористену (хоча <br />на це було ",
        { id: "dropdown2", text: "згаяно " },
        " чимало днів), <br />Дар'явауш  ",
        { id: "dropdown3", text: "розпорядився " },
        "<br />",
        { id: "dropdown4", text: "послати " },
        " гінця до царя скіфів.",
      ],
      options: [
        { id: "А", text: "неозначена форма" },
        { id: "Б", text: "особове дієслово" },
        { id: "В", text: "безособова форма" },
        { id: "Г", text: "дієприкметник" },
        { id: "Д", text: "дієприслівник" },
      ],
      correctAnswers: {
        dropdown1: "Д",
        dropdown2: "В",
        dropdown3: "Б",
        dropdown4: "А",
      },
      showAnswerOptions: true,
      dropdownAmount: 4,
      isTest: false,
    },
    {
      id: 6,
      question: "6. З’ясуйте синтаксичну роль виділених дієслів.",
      dropdownQuestionParts: [
        "<i>1) ",
        { id: "dropdown1", text: "Розлучатися " },
        " завжди важко.<br  /> 2) Сонячний промінь помчав ",
        { id: "dropdown2", text: "будити " },
        " дітей.<br />3) Подорожні захотіли  ",
        { id: "dropdown3", text: "відвідати " },
        " сад бузку.<br />4) Батько порадив ",
        { id: "dropdown4", text: "підпливати " },
        " сад бузку.<br />ближче.</i>",
      ],
      options: [
        { id: "А", text: "підмет" },
        { id: "Б", text: "присудок" },
        { id: "В", text: "означення" },
        { id: "Г", text: "додаток" },
        { id: "Д", text: "обставина" },
      ],
      correctAnswers: {
        dropdown1: "А",
        dropdown2: "Д",
        dropdown3: "Б",
        dropdown4: "Г",
      },
      showAnswerOptions: true,
      dropdownAmount: 4,
      isTest: false,
    },
    {
      id: 7,
      question: "7. Розподіліть дієслова у дві чарунки:",
      isGame: true,
      initialWords: [
        { id: 1, text: "Прос..те", type: "rightBucket" },
        { id: 2, text: "мел..те", type: "leftBucket" },
        { id: 3, text: "ход..мо", type: "rightBucket" },
        { id: 4, text: "люб..мо", type: "leftBucket" },
        { id: 5, text: "бор..шся", type: "leftBucket" },
        { id: 6, text: "кле..ш", type: "rightBucket" },
        { id: 7, text: "біга..те", type: "leftBucket" },
        { id: 8, text: "стел..мо", type: "rightBucket" },
        { id: 9, text: "вар..ться", type: "rightBucket" },
        { id: 10, text: "ліку..теся", type: "leftBucket" },
        { id: 11, text: "клич..те", type: "leftBucket" },
        { id: 12, text: "бач..ш", type: "rightBucket" },
      ],
      leftBucketTitle: "<h3>З буквою <i>е (є)</i></h3>",
      rightBucketTitle: "<h3>З буквою <i>и (ї)</i></h3>",
    },
  ],
  // Додати нові питання сюди
};
