import harry_potter from "./images/harry_potter.png";
import avgust from "./images/avgust.png";

export const test_image = {
  title: "1. Вступ. Художній твір в історико-культурному контексті доби",
  questions: [
    {
      id: 1,
      question: "1. Визначте вид мистецтва за зображенням.",
      options: [
        { id: "А", text: "кіно" },
        { id: "Б", text: "танець" },
        { id: "В", text: "живопис" },
        { id: "Г", text: "скульптура" },
        { id: "Д", text: "архітектура" },
      ],
      videoPath: "https://www.youtube.com/embed/MV_3Dpw-BRY",
      addClass: "horizontal",
      correctAnswer: "А",
      isTest: true,
    },
    {
      id: 2,
      question: "1. Визначте вид мистецтва за зображенням.",
      options: [
        { id: "А", text: "кіно" },
        { id: "Б", text: "танець" },
        { id: "В", text: "живопис" },
        { id: "Г", text: "скульптура" },
        { id: "Д", text: "архітектура" },
      ],
      imgPath: harry_potter,
      addClass: "horizontal",
      correctAnswer: "А",
      isTest: true,
    },
    {
      id: 3,
      question: "1. Визначте вид мистецтва за зображенням.",
      options: [
        { id: "А", text: "кіно" },
        { id: "Б", text: "танець" },
        { id: "В", text: "живопис" },
        { id: "Г", text: "скульптура" },
        { id: "Д", text: "архітектура" },
      ],
      imgPath: avgust,
      addClass: "vertical",
      correctAnswer: "Г",
      isTest: true,
    },
  ],
};
