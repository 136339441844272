export const module07 = {
  title: "§ 12-14. Біблія та українська література.",
  questions: [
    {
      id: 1,
      question: "1. НЕПРАВИЛЬНИМ є твердження, що Давид був",
      options: [
        { id: "А", text: "царем давнього Ізраїлю" },
        { id: "Б", text: "пастухом і музикантом" },
        { id: "В", text: "збирачем податків" },
        { id: "Г", text: "автором псалмів" },
      ],
      correctAnswer: "В",
      isTest: true,
    },
    {
      id: 2,
      question:
        "2. «Древо над потоком» у «Давидових псалмах. Псалом 1» Ліни Костенко символізує",
      options: [
        { id: "А", text: "самотність грішника й облудника" },
        { id: "Б", text: "красу весняного пейзажу" },
        { id: "В", text: "сіру й напівмертву душу" },
        { id: "Г", text: "стійкість праведника" },
      ],
      correctAnswer: "Г",
      isTest: true,
    },
    {
      id: 3,
      question: "3. У «Давидових псалмах. Псалом 1» Ліни Костенко НЕМАЄ образу",
      options: [
        { id: "А", text: "садів" },
        { id: "Б", text: "дощів" },
        { id: "В", text: "пастуха" },
        { id: "Г", text: "горобця" },
      ],
      correctAnswer: "В",
      isTest: true,
    },
    {
      id: 4,
      question: "4. НЕПРАВИЛЬНИМ є твердження про повість «Мене звати Варвара»",
      options: [
        { id: "А", text: "авторкою повісті є Дзвінка Матіяш" },
        { id: "Б", text: "розповідь ведеться від третьої особи" },
        { id: "В", text: "головна героїня веде щоденник" },
        { id: "Г", text: "Варвара мешкає в Києві" },
      ],
      correctAnswer: "Б",
      isTest: true,
    },
    {
      id: 5,
      question: "5. Упізнайте літературну героїню за її характеристикою.",
      dropdownQuestionParts: [
        "<i> 1) фанатка диктантів, сувора  <br />",
        { id: "dropdown1" },
        "<br /><i> 2) худорлява дівчинка, фанатка змін <br />",
        { id: "dropdown2" },
        "<br /> <i>3) любила читати, не цікавилася модами <br />",
        { id: "dropdown3" },
        "<br /> <i>4) видатна, незвичайна жінка, подорожувала Європою. <br />",
        { id: "dropdown4" },
      ],
      options: [
        { id: "Ольга Миколаївна", text: "Ольга Миколаївна" },
        { id: "Варвара Ханенко", text: "Варвара Ханенко" },
        { id: "Варвара Сабадаш", text: "Варвара Сабадаш" },
        { id: "свята Варвара", text: "свята Варвара" },
        { id: "Маргарита", text: "Маргарита" },
      ],
      correctAnswers: {
        dropdown1: "Ольга Миколаївна",
        dropdown2: "Варвара Сабадаш",
        dropdown3: "свята Варвара",
        dropdown4: "Варвара Ханенко",
      },
      showAnswerOptions: false,
      dropdownAmount: 4,
      isTest: false,
    },
    {
      id: 6,
      question:
        "6. Розподіліть фрагменти творів.</br><b><i style='text-align: center; display: block;'>Фрагменти:</i></b>",
      isGame: true,

      initialWords: [
        {
          id: 1,
          text: "«самотужки опановую дедуктивний метод»",
          type: "rightBucket",
        },
        {
          id: 2,
          text: "«і хоч про нього скажуть: навіжений, то не біда»",
          type: "leftBucket",
        },
        { id: 3, text: "«із тих плодів посіються сади»", type: "leftBucket" },
        {
          id: 4,
          text: "«варвари, самі розумієте, не мій орієнтир»",
          type: "rightBucket",
        },
        {
          id: 5,
          text: "«дощі розмиють слід його садалій»",
          type: "leftBucket",
        },
        {
          id: 6,
          text: "«неврівноважений холерик із садистичними нахилами»",
          type: "rightBucket",
        },
        {
          id: 7,
          text: "«ікони у візантійському стилі – суворі»",
          type: "rightBucket",
        },
        {
          id: 8,
          text: "«дорога ницих в землю западеться»",
          type: "leftBucket",
        },
      ],
      leftBucketTitle: "«Давидові псалми. Псалом 1»",
      rightBucketTitle: "«Мене звати Варвара»",
    },
    {
      id: 7,
      question:
        "7. Послухайте «Псалом 1» Ліни Костенко (1 хв 46 хв) у виконанні Івана Галушки – учня Малокаховської ЗЗСО. Підготуйтеся до виразного читання цього твору й запишіть подібне відео для класного / шкільного конкурсу.",
      videoPath:
        "https://www.youtube.com/embed/VChnN3NI0J0?si=pBMhrSL3KnNEl7Ae",
      addClass: "horizontal",
      isTest: true,
      isCreativeTask: true,
    },

    // Додати нові питання сюди
  ],
};
