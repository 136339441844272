export const module9 = {
  title: "§ 9. Граматичні помилки.",
  questions: [
    {
      id: 1,
      question:
        "1. НЕПРАВИЛЬНО утворено форму кличного відмінка однини у варіанті",
      options: [
        { id: "А", text: "Валерій – Валерію" },
        { id: "Б", text: "Юлія – Юлію" },
        { id: "В", text: "Петро – Петре" },
        { id: "Г", text: "Василина – Василино" },
      ],
      correctAnswer: "Б",
      isTest: true,
    },
    {
      id: 2,
      question: "2. НЕПРАВИЛЬНО утворено форму іменників у варіанті",
      options: [
        { id: "А", text: "школяром, кашою, столярем" },
        { id: "Б", text: "мишею, зброярем, футляром" },
        { id: "В", text: "учителем, доларом, слухачем" },
        { id: "Г", text: "аптекарем, сторожем, комаром" },
      ],
      correctAnswer: "А",
      isTest: true,
    },
    {
      id: 3,
      question:
        "3. Помилково вжито форму ступеня порівняння прислівника у варіанті",
      options: [
        { id: "А", text: "Глибше орати – більше хліба жувати." },
        { id: "Б", text: "Усюди добре, а вдома найліпше." },
        { id: "В", text: "Молоде орля, та вище старого літає!" },
        { id: "Г", text: "Найраніше встанеш, то більше зробиш." },
      ],
      correctAnswer: "Г",
      isTest: true,
    },
    {
      id: 4,
      question: "4. Правильно поєднано числівник з іменником у варіанті",
      options: [
        {
          id: "А",
          text: "шестеро граблів",
        },
        { id: "Б", text: "три професори" },
        { id: "В", text: "півтора кілограми" },
        { id: "Г", text: "дві п’ятих відсотки" },
      ],
      correctAnswer: "Б",
      isTest: true,
    },
    {
      id: 5,
      question:
        '5. Граматично правильне речення утвориться, якщо до фрагмента <span class="text-wow">«Переглянувши фільм, …»',
      options: [
        {
          id: "А",
          text: "його треба порадити всім.",
        },
        { id: "Б", text: "мене вразила його розв’язка." },
        { id: "В", text: "він захопив повчальним підтекстом." },
        { id: "Г", text: "ми написали есе про власні враження." },
      ],
      correctAnswer: "Г",
      isTest: true,
    },
    {
      id: 6,
      question:
        '6. Граматично правильним є варіант послідовного заповнення пропусків <br> <span class="text-wow">_____, сьогодні Івана Купала – _____ свято літа, тому _____ до річки.',
      options: [
        {
          id: "А",
          text: "Катрусю, найромантичніше, давай підемо",
        },
        { id: "Б", text: "Катрусе, саме романтичне, ходімо" },
        { id: "В", text: "Катрусю, найромантичніше, ходімо" },
        { id: "Г", text: "Катруся, найбільш романтичніше, пішли" },
      ],
      correctAnswer: "В",
      isTest: true,
    },
    {
      id: 7,
      question:
        '7. З’ясуйте закінчення іменників родового відмінка. Розподіліть іменники у дві чарунки –  <span class="text-wow">іменники із закінченням -а(-я) / іменники із закінченням -у(-ю)',
      isGame: true,
      initialWords: [
        { id: 1, text: "Патріотизм", type: "rightBucket" },
        { id: 2, text: "смартфон", type: "leftBucket" },
        { id: 3, text: "Шевченко", type: "leftBucket" },
        { id: 4, text: "сон", type: "rightBucket" },
        { id: 5, text: "ремонт", type: "rightBucket" },
        { id: 6, text: "явір", type: "leftBucket" },
        { id: 7, text: "трамвай", type: "leftBucket" },
        { id: 8, text: "спосіб", type: "rightBucket" },
        { id: 9, text: "мороз", type: "rightBucket" },
        { id: 10, text: "квадрат", type: "leftBucket" },
        { id: 11, text: "грам", type: "leftBucket" },
        { id: 12, text: "ютуб", type: "rightBucket" },
        { id: 13, text: "сад", type: "rightBucket" },
        { id: 14, text: "січень", type: "leftBucket" },
        { id: 15, text: "вівторок", type: "leftBucket" },
        { id: 16, text: "бузок", type: "rightBucket" },
        { id: 17, text: "ніс", type: "leftBucket" },
        { id: 18, text: "бадмінтон", type: "rightBucket" },
        { id: 19, text: "хліб", type: "leftBucket" },
        { id: 20, text: "метрополітен", type: "rightBucket" },
        { id: 21, text: "майдан", type: "rightBucket" },
        { id: 22, text: "Житомир", type: "leftBucket" },
        { id: 23, text: "лісок", type: "leftBucket" },
        { id: 24, text: "Дунай", type: "rightBucket" },
      ],
      leftBucketTitle: "Іменники із закінченням -а(-я)",
      rightBucketTitle: "Іменники із закінченням -у(-ю)",
    },
    // Додати нові питання сюди
  ],
};
