export const module20_21 = {
  title: "§ 20–21. Граматична основа двоскладного речення. Присудок",
  questions: [
    {
      id: 1,
      question: "1. Простий дієслівний присудок ужито в реченні",
      options: [
        { id: "А", text: "Вода була м’яка, з кислинкою свіжости, ситна." },
        { id: "Б", text: "Їхати до Оржиці Іван вирішив битим шляхом." },
        { id: "В", text: "Липи будуть цвісти вже від середини червня." },
        { id: "Г", text: "Чигирин став схожий на дівчину у віночку." },
      ],
      correctAnswer: "В",
      isTest: true,
    },
    {
      id: 2,
      question: "2. Простий дієслівний присудок ужито в реченні",
      options: [
        { id: "А", text: "Сонце починало опускатися до небосхилу." },
        { id: "Б", text: "Ротмістр стелився барвінком під ноги Оксані." },
        { id: "В", text: "Цьогоріч літо видалося надзвичайно спекотним." },
        { id: "Г", text: "Русяве волосся підв’язане голубою стрічкою." },
      ],
      correctAnswer: "Б",
      isTest: true,
    },
    {
      id: 3,
      question: "3. Простий дієслівний присудок ужито в реченні",
      options: [
        { id: "А", text: "Лісова ділянка була добре впорана." },
        { id: "Б", text: "Відлита в слові кров моя дзвінка." },
        { id: "В", text: "Ліс був сповнений пташиного гомону." },
        { id: "Г", text: "Дорога заплуталась у яворах." },
      ],
      correctAnswer: "Г",
      isTest: true,
    },
    {
      id: 4,
      question: "4. Складений дієслівний присудок ужито в реченні",
      options: [
        { id: "А", text: "Спробуй знайти в бруді свої діаманти." },
        { id: "Б", text: "Подвір’я було велике й доглянуте." },
        { id: "В", text: "За пів години пліт був готовий." },
        { id: "Г", text: "Полягали спати вже всі водограї." },
      ],
      correctAnswer: "А",
      isTest: true,
    },
    {
      id: 5,
      question: "5. Складений іменний присудок ужито в реченні",
      options: [
        { id: "А", text: "Навколо панувала весела метушня." },
        { id: "Б", text: "Пташині сутінки лягли на луки." },
        { id: "В", text: "Сонце тільки починало сходити." },
        { id: "Г", text: "День стояв зовсім весняний." },
      ],
      correctAnswer: "Г",
      isTest: true,
    },

    {
      id: 6,
      question:
        "6. Доберіть приклад до кожного виду присудка.",
      dropdownQuestionParts: [
        "1 простий присудок ",
        { id: "dropdown1", },
        "<br />",
        "2 складений іменний присудок ",
        { id: "dropdown2", },
        "<br />",
        "3 складений дієслівний присудок ",
        { id: "dropdown3", },
        "<br />"
      ],
      options: [
        { id: "А", text: "Довгождана екскурсія музеями Переяслава-Хмельницького." },
        { id: "Б", text: "Незабаром тут будуть експонувати й найдавніші речі. " },
        { id: "В", text: "Так хочеться оглянути весь історико-етнографічний заповідник!" },
        { id: "Г", text: "Адже Переяслав — це місто музеїв." },
      ],
      correctAnswers: {
        dropdown1: "Б",
        dropdown2: "Г",
        dropdown3: "В",
      },
      showAnswerOptions: true,
      dropdownAmount: 4,
      isTest: false,
    },
    {
      id: 7,
      question:
        "7. Завдання. Розподіліть речення за видами присудків у три чарунки: 1 – простий присудок; 2 – складений іменний присудок; 3 – складений дієслівний присудок.",
      isGame: true,
      initialWords: [
        { id: 1, text: "Дід любив спати під дубом. ", type: "rightBucket" },
        { id: 2, text: "Цвітуть чорнобривці, жоржини й портулак. ", type: "leftBucket" },
        { id: 3, text: "Аделя – майстриня тонких візерунків. ", type: "centerBucket" },
        { id: 4, text: "Грицик перечепився через купину й заорав носом у траву. ", type: "leftBucket" },
        { id: 5, text: "До роботи Петро завжди був спраглий.", type: "centerBucket" },
        { id: 6, text: "Треба набутися у хвилині радості. ", type: "rightBucket" },
        { id: 7, text: "Вода знову почала пришвидшувати свій біг. ", type: "rightBucket" },
        { id: 8, text: "Незабаром будуть танцювати й наші. ", type: "leftBucket" },
        { id: 9, text: "Десь тут має бути криничка.", type: "rightBucket" },
        { id: 10, text: "Моя любов неподільна, непорушна. ", type: "centerBucket" },
        { id: 11, text: "Дівчина буде сміятися дзвінко та щиро. ", type: "leftBucket" },
        { id: 12, text: "Ця книжка не моя.", type: "centerBucket" },
      
      ],
      leftBucketTitle: "Простий присудок",
      centerBucketTitle: "Складений іменний присудок",
      rightBucketTitle: "Складений дієслівний присудок",
      bucketsAmount: 3,
    },
    // Додати нові питання сюди
  ],
};
