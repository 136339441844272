import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";
import "./DropdownTask.css";

const DropdownTask = forwardRef(
  (
    {
      onAllDropdownsSelected,
      dropdownQuestionParts,
      question,
      options, // Принимаем options как пропс
      showAnswerOptions,
      dropdownAmount,
    },
    ref
  ) => {
    const initialDropdownValues = {};
    for (let i = 1; i <= dropdownAmount; i++) {
      initialDropdownValues[`dropdown${i}`] = "";
    }

    const [dropdownValues, setDropdownValues] = useState(initialDropdownValues);

    useEffect(() => {
      const allSelected = Object.values(dropdownValues).every(
        (value) => value !== ""
      );
      if (allSelected) {
        onAllDropdownsSelected(dropdownValues);
        console.log("yes");
      }
      console.dir(initialDropdownValues);
      console.dir(dropdownValues);
    }, [dropdownValues, onAllDropdownsSelected]);

    const handleDropdownChange = (event) => {
      const { id, value } = event.target;
      setDropdownValues((prevValues) => ({
        ...prevValues,
        [id]: value,
      }));
    };

    const resetDropdownValues = () => {
      const newDropdownValues = {};
      for (let i = 1; i <= dropdownAmount; i++) {
        newDropdownValues[`dropdown${i}`] = "";
      }
      setDropdownValues(newDropdownValues);
    };

    useImperativeHandle(ref, () => ({
      resetDropdownValues,
    }));

    const renderDropdownPart = (part) => {
      if (typeof part === "string") {
        return <span dangerouslySetInnerHTML={{ __html: part }}></span>;
      } else {
        return (
          <>
            <span className="highlight">{part.text}</span>
            <select
              id={part.id}
              className="dropdown"
              value={dropdownValues[part.id]}
              onChange={handleDropdownChange}
            >
              <option value="" disabled>
                {part.id.replace("dropdown", "")}
              </option>
              {options.map((option) => (
                <option key={option.id} value={option.id}>
                  {option.id}
                </option>
              ))}
            </select>
          </>
        );
      }
    };

    return (
      <>
        <div
          className="title-question"
          dangerouslySetInnerHTML={{ __html: question }}
        ></div>

        <div className="dropdown-task">
          <div className="test-question">
            {dropdownQuestionParts.map((part, index) => (
              <React.Fragment key={index}>
                {renderDropdownPart(part)}
              </React.Fragment>
            ))}
          </div>
          {showAnswerOptions && (
            <div className="test-answers">
              <ul
                className="answer-options-dropdown-task"
                style={{ paddingLeft: 0 }}
              >
                {options.map((option) => (
                  <li key={option.id}>
                    <div className="option-circle">{option.id}</div>
                    {option.text}
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
      </>
    );
  }
);

export default DropdownTask;
