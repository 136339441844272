export const module20 = {
  title: "Мотиви середньовіччя в сучасній зарубіжній літературі. Юстейн Ґордер «Середньовіччя» (із роману «Світ Софії»)",
  questions: [
    {
      id: 1,
      question: "1. Якою була мета Альберто Кнокса у навчанні Софії філософії?",
      options: [
        { id: "А", text: "Підготувати її до вступу в університет" },
        { id: "Б", text: "Розвинути її критичне мислення та допитливість" },
        { id: "В", text: "Зробити з неї професійного філософа" },
        { id: "Г", text: "Відволікти її від реального світу" },
      ],
      correctAnswer: "Б",
      isTest: true,
    },
    {
      id: 2,
      question: "2. Що символізує 'кролик, якого витягують з капелюха чарівника' у книзі?",
      options: [
        { id: "А", text: "магію та ілюзії" },
        { id: "Б", text: "несподівані повороти сюжету" },
        { id: "В", text: "виникнення Всесвіту з нічого" },
        { id: "Г", text: "появу нових філософських ідей" },
      ],
      correctAnswer: "В",
      isTest: true,
    },
    {
      id: 3,
      question: "3. Яку важливу істину усвідомлює Софія наприкінці книги?",
      options: [
        { id: "А", text: "Вона є вигаданим персонажем у чужій історії" },
        { id: "Б", text: "Вона насправді донька майора Кнага" },
        { id: "В", text: "Альберто Кнокс - це її справжній батько" },
        { id: "Г", text: "Філософія не має практичного застосування" },
      ],
      correctAnswer: "А",
      isTest: true,
    },
    {
      id: 4,
      question: "4. Яка філософська концепція найбільше пов'язана з ідеєю 'печери' Платона в романі?",
      options: [
        { id: "А", text: "матеріалізм" },
        { id: "Б", text: "ідеалізм" },
        { id: "В", text: "скептицизм" },
        { id: "Г", text: "емпіризм" },
      ],
      correctAnswer: "Б",
      isTest: true,
    },
    {
      id: 5,
      question: "5. Як роман 'Світ Софії' демонструє зв'язок між філософією та повсякденним життям?",
      options: [
        { id: "А", text: "Через абстрактні філософські дискусії" },
        { id: "Б", text: "Через застосування філософських ідей до буденних ситуацій" },
        { id: "В", text: "Через історичні приклади великих філософів" },
        { id: "Г", text: "Через складні метафізичні концепції" },
      ],
      correctAnswer: "Б",
      isTest: true,
    },
    {
      id: 6,
      question: "6. Яку роль відіграє персонаж Хільде в структурі роману?",
      options: [
        { id: "А", text: "альтер-его Софії" },
        { id: "Б", text: "читачка історії Софії всередині самого роману" },
        { id: "В", text: "реальна дочка автора книги" },
        { id: "Г", text: "філософський наставник Софії" },
      ],
      correctAnswer: "Б",
      isTest: true,
    },
    // Додайте нові питання тут
  ],
};
