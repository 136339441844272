import { Component } from "react";

import ".//ModuleContainer.css";
import ".//responsive.css";

class ModuleContainer extends Component {
  render() {
    const { component: Component, module } = this.props;
    return (
      <section className="module-background">
        <div className="module-container">
          <Component module={module} />
        </div>
      </section>
    );
  }
}

export default ModuleContainer;
