import image001 from "./images/ukrlit/image001.jpg";
import image002 from "./images/ukrlit/image002.jpg";
import image003 from "./images/ukrlit/image003.png";
import image004 from "./images/ukrlit/image004.jpg";
import image005 from "./images/ukrlit/image005.jpg";

export const module01 = {
  title: "§ 1. Вступ. Художній твір в історико-культурному контексті доби.",
  questions: [
    {
      id: 1,
      question: "1. Визначте вид мистецтва за зображенням.",
      options: [
        { id: "А", text: "кіно" },
        { id: "Б", text: "танець" },
        { id: "В", text: "музика" },
        { id: "Г", text: "скульптура" },
        { id: "Д", text: "архітектура" },
      ],
      imgPath: image001,
      addClass: "vertical",
      correctAnswer: "Г",
      isTest: true,
    },
    {
      id: 2,
      question: "2. Визначте вид мистецтва за зображенням.",
      options: [
        { id: "А", text: "кіно" },
        { id: "Б", text: "танець" },
        { id: "В", text: "музика" },
        { id: "Г", text: "скульптура" },
        { id: "Д", text: "архітектура" },
      ],
      imgPath: image002,
      addClass: "horizontal",
      correctAnswer: "Д",
      isTest: true,
    },
    {
      id: 3,
      question: "3. Визначте вид мистецтва за зображенням.",
      options: [
        { id: "А", text: "кіно" },
        { id: "Б", text: "танець" },
        { id: "В", text: "живопис" },
        { id: "Г", text: "скульптура" },
        { id: "Д", text: "архітектура" },
      ],
      imgPath: image003,
      addClass: "horizontal",
      correctAnswer: "А",
      isTest: true,
    },
    {
      id: 4,
      question: "4. Визначте вид мистецтва за зображенням.",
      options: [
        { id: "А", text: "кіно" },
        { id: "Б", text: "танець" },
        { id: "В", text: "живопис" },
        { id: "Г", text: "скульптура" },
        { id: "Д", text: "архітектура" },
      ],
      imgPath: image004,
      addClass: "horizontal",
      correctAnswer: "Б",
      isTest: true,
    },
    {
      id: 5,
      question: "5. Визначте вид мистецтва за зображенням.",
      options: [
        { id: "А", text: "кіно" },
        { id: "Б", text: "танець" },
        { id: "В", text: "живопис" },
        { id: "Г", text: "скульптура" },
        { id: "Д", text: "архітектура" },
      ],
      imgPath: image005,
      addClass: "horizontal",
      correctAnswer: "В",
      isTest: true,
    },
    {
      id: 6,
      question:
        "6. Розподіліть ознаки за епохами. </br><b><i style='text-align: center; display: block;'>Ознаки:</i></b>",
      isGame: true,

      initialWords: [
        {
          id: 1,
          text: "погляд на людину як на унікальне явище природи",
          type: "leftBucket",
        },
        {
          id: 2,
          text: "розвиток іконопису, мистецтва мозаїки, храмового будівництва",
          type: "rightBucket",
        },
        {
          id: 3,
          text: "проповідування аскетичних ідеалів",
          type: "rightBucket",
        },
        {
          id: 4,
          text: "перенесення людських рис на природу і навіть на богів, яких греки уявляли у вигляді людей – безсмертних, прекрасних і вічно молодих",
          type: "leftBucket",
        },
        {
          id: 5,
          text: "віра в потойбічний світ, що стала могутнім знаряддям у руках церкви",
          type: "rightBucket",
        },
        {
          id: 6,
          text: "одвічне прагнення до гармонійного розвитку",
          type: "leftBucket",
        },
        { id: 7, text: "релігійна тематика", type: "rightBucket" },
      ],
      leftBucketTitle: "Античність",
      rightBucketTitle: "Середньовіччя",
    },
    {
      id: 7,
      question:
        "7. Переглянувши кліп «Шум» від гурту «Go A» (4 хв 20 с), напишіть невеликий роздум на тему «Чому в новітню епоху митці звертаються до давньої міфології?». Висловіть свої враження від побаченого /почутого. ",
      videoPath:
        "https://www.youtube.com/embed/sDeMfL8Ib9A?si=qLwFSujw-iSCi4wj",
      addClass: "horizontal",
      isTest: true,
      isCreativeTask: true,
    },
    // Додати нові питання сюди
  ],
};
