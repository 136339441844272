import { Component } from "react";

import ".//TheoryContainer.css";

class TheoryContainer extends Component {
  render() {
    const { content } = this.props;
    // const { component: Component, module } = this.props;
    return (
      <section className="module-background">
        <div className="module-container">{content}</div>
      </section>
    );
  }
}

export default TheoryContainer;
