// AnswerOption.js
import React, { Component } from "react";
import "./AnswerOption.css";

class AnswerOption extends Component {
  render() {
    const { id, text, selectedOption, status, onClick } = this.props;

    let className = "option-circle";
    if (selectedOption === id) className += " selected";
    if (status === "incorrect") className += " incorrect";
    if (status === "correct") className += " correct";

    return (
      <li>
        <div className={className} onClick={() => onClick(id)}>
          {id}
        </div>
        <span dangerouslySetInnerHTML={{ __html: text }}></span>
      </li>
    );
  }
}

export default AnswerOption;
