import { Component } from "react";
import Header from "../header_h/Header";

import Footer from "../footer/Footer";

import ModuleContainer from "../module-container/ModuleContainer";

class ModulePage extends Component {
  render() {
    const { userName, module } = this.props;

    const { component: Component } = this.props;
    return (
      <>
        <Header userName={userName} />
        <ModuleContainer component={Component} module={module} />
        <Footer />
      </>
    );
  }
}

export default ModulePage;
